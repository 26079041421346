import { useTranslation } from "react-i18next";
import styles from "../Footer/Footer.module.css";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import facebook from "../../assets/Images/faceBook.png";
import twitter from "../../assets/Images/twitter.png";
import pinrest from "../../assets/Images/pinrest.png";
import linkedin from "../../assets/Images/linkedin.png";
import instagram from "../../assets/Images/instagram.webp";
import tiktok from "../../assets/Images/tiktok.webp";
import playStore from "../../assets/Images/playStore.png";
import appStore from "../../assets/Images/appStore.png";
import phone from "../../assets/Images/phone.png";
import locationImg from "../../assets/Images/locationImg.png";
import gmail from "../../assets/Images/gmail.png";
import { FooterData } from "../../mockData";

//-------Create a component for footer--------
function Footer() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  function formatCopyrightYear() {
    const currentYear = new Date().getFullYear();
    const formattedYearRange =
      currentYear + "-" + (currentYear + 1).toString().slice(-2);
    return formattedYearRange;
  }

  const handleInfoClick = (link, type) => {
    navigate(link, { state: type });
  };

  return (
    <>
      <section className={styles.footerSection}>
        <div className={styles.footerManage}>
          <Container>
            <div className={styles.footerWrapper}>
              <div className={styles.footerInfo}>
                <div className={styles.footerItems}>
                  <div className={styles.footerHeading}>
                    <div className={styles.verticalDivider}></div>
                    <div>Contact Us</div>
                  </div>
                  <div className={styles.infoSection}>
                    <div>
                      <img
                        src={locationImg}
                        alt="location-icon-city2city"
                        loading="lazy"
                      />
                    </div>
                    <div style={{ maxWidth: "220px" }}>
                      85 Great Portland Street, First Floor, London, W1W 7LT
                    </div>
                  </div>
                  <div className={styles.infoSection}>
                    <div>
                      <img
                        src={phone}
                        alt="phone-icon-city2city"
                        loading="lazy"
                      />
                    </div>
                    <div>+44 333 335 6525</div>
                  </div>
                  <div className={styles.infoSection}>
                    <div>
                      <img
                        src={gmail}
                        alt="gmail-icon-city2city"
                        loading="lazy"
                      />
                    </div>
                    <div className={styles.emailHidden}>
                      {/* info@city2city.co.uk */}
                    </div>
                  </div>
                </div>
                <div className={styles.footerItems}>
                  <div className={styles.footerHeading}>
                    <div className={styles.verticalDivider}></div>
                    <div>Services</div>
                  </div>
                  <div>Furniture and other items</div>
                  <div>Home removals</div>
                  <div>Business to Business</div>
                </div>
                <div className={styles.footerItems}>
                  <div className={styles.footerHeading}>
                    <div className={styles.verticalDivider}></div>
                    <div>Social Media</div>
                  </div>
                  <div className={styles.footerSocialSection}>
                    <div className={styles.footerSocialInfo}>
                      <div>
                        <a
                          href={process.env.REACT_APP_FACEBOOK_LINK}
                          target="_blank"
                          rel="noopener noreferrer"
                          aria-label="facebook-link-city2city"
                        >
                          <img
                            src={facebook}
                            alt="facebook-link-city2city"
                            loading="lazy"
                          />
                        </a>
                      </div>
                      <div>
                        <a
                          href={process.env.REACT_APP_TWITTER_LINK}
                          target="_blank"
                          rel="noopener noreferrer"
                          aria-label="twitter-link-city2city"
                        >
                          <img
                            src={twitter}
                            alt="twitter-link-city2city"
                            loading="lazy"
                          />
                        </a>
                      </div>
                      <div>
                        <a
                          href={process.env.REACT_APP_PINTEREST_LINK}
                          target="_blank"
                          rel="noopener noreferrer"
                          aria-label="pinterest-link-city2city"
                        >
                          <img
                            src={pinrest}
                            alt="pinterest-link-city2city"
                            loading="lazy"
                          />
                        </a>
                      </div>
                    </div>

                    <div className={styles.footerSocialInfo}>
                      <div>
                        <a
                          href={process.env.REACT_APP_LINKEDIN_LINK}
                          target="_blank"
                          rel="noopener noreferrer"
                          aria-label="linkedIn-link-city2city"
                        >
                          <img
                            src={linkedin}
                            alt="linkedIn-link-city2city"
                            loading="lazy"
                          />
                        </a>
                      </div>
                      <div>
                        <a
                          href={process.env.REACT_APP_INSTAGRAM_LINK}
                          target="_blank"
                          rel="noopener noreferrer"
                          aria-label="linkedIn-link-city2city"
                        >
                          <img
                            src={instagram}
                            alt="instagram-link-city2city"
                            loading="lazy"
                          />
                        </a>
                      </div>
                      <div>
                        <a
                          href={process.env.REACT_APP_TIKTOK_LINK}
                          target="_blank"
                          rel="noopener noreferrer"
                          aria-label="linkedIn-link-city2city"
                        >
                          <img
                            src={tiktok}
                            alt="TikTok-link-city2city"
                            loading="lazy"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.footerItems}>
                  <div className={styles.footerHeading}>
                    <div className={styles.verticalDivider}></div>
                    <div>Customer App</div>
                  </div>
                  <div
                    className={`${styles.footerSocialInfo} ${styles.footerSocialInfoStore}`}
                  >
                    <div>
                      <a
                        href={process.env.REACT_APP_CUSTOMER_GOOGLE}
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="customer-play-store-city2city"
                      >
                        <img
                          src={playStore}
                          alt="customer-play-store-city2city"
                          loading="lazy"
                        />
                      </a>
                    </div>
                    <div>
                      <a
                        href={process.env.REACT_APP_CUSTOMER_APPLE}
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="driver-play-store-city2city"
                      >
                        <img
                          src={appStore}
                          alt="driver-play-store-city2city"
                          loading="lazy"
                        />
                      </a>
                    </div>
                  </div>
                  <div className={styles.footerItems}>
                    <div className={styles.footerHeading}>
                      <div className={styles.verticalDivider}></div>
                      <div>Driver App</div>
                    </div>
                    <div
                      className={`${styles.footerSocialInfo} ${styles.footerSocialInfoStore}`}
                    >
                      <div>
                        <a
                          href={process.env.REACT_APP_DRIVER_GOOGLE}
                          target="_blank"
                          rel="noopener noreferrer"
                          aria-label="customer-apple-store-city2city"
                        >
                          <img
                            src={playStore}
                            alt="customer-apple-store-city2city"
                            loading="lazy"
                          />
                        </a>
                      </div>
                      <div>
                        <a
                          href={process.env.REACT_APP_DRIVER_APPLE}
                          target="_blank"
                          rel="noopener noreferrer"
                          aria-label="driver-apple-store-city2city"
                        >
                          <img
                            src={appStore}
                            alt="driver-apple-store-city2city"
                            loading="lazy"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
        <div className={styles.copyText}>
          <div>
            {t("COPYRIGHT")} &copy; {t("City2City")} {formatCopyrightYear()}
          </div>
          <div className={styles.footerPolicy}>
            {FooterData.map((val, ind) => {
              return (
                <div
                  key={ind}
                  className={styles.policyText}
                  onClick={() => handleInfoClick(val.link, val.type)}
                >
                  {val.text}
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
}
export default Footer;
