import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import First from "./Tabs/First";
import Second from "./Tabs/Second";
import Third from "./Tabs/Third";
import Fourth from "./Tabs/Fourth";
import Fifth from "./Tabs/Fifth";
import { resetAllJobData, setActiveTab } from "../../../store/slices/createJob";
import { useLocation } from "react-router-dom";
import B2bEnquiry from "./Tabs/B2bEnquiry";
import Header from "../../Header/Header";
import { Helmet } from "react-helmet-async";

const StepsLayout = ({ children }) => {
  return (
    <div>
      <Header children={children} />
    </div>
  );
};

function Furnitures() {
  const dispatch = useDispatch();
  const location = useLocation();
  const activeTab = useSelector((state) => state.createJob.activeTab);

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, [activeTab]);

  useEffect(() => {
    if (location.state?.sureToClear || location.state == null) {
      dispatch(resetAllJobData());
      dispatch(setActiveTab("first"));
    } else {
    }
  }, []);

  const renderTab = (tabName) => {
    switch (tabName) {
      case "first":
        return <First />;
      case "second":
        return <Second />;
      case "third":
        return <Third />;
      case "fourth":
        return <Fourth />;
      case "fifth":
        return <Fifth />;
      case "b2bEnquiry":
        return <B2bEnquiry />;
      default:
        return null; // You can handle other cases or unknown tabs here
    }
  };

  return (
    <>
      <Helmet>
        <title>
          City2City | Book a Move – House & Van Removals, Same-Day Service
        </title>
        <meta
          name="description"
          content="City2City - Book your move with City2City! We offer house removals, van removals, office relocations, furniture transport, and same-day removals. Get a cost-effective move today."
        />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <StepsLayout>{renderTab(activeTab)}</StepsLayout>
    </>
  );
}

export default Furnitures;
