import React from "react";

const StructuredData = () => {
  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    name: "City2city",
    url: "https://www.city2city.co.uk",
    description:
      "City2City offers expert van removals, house removals, office relocations, and same-day removals. Book your move today for a stress-free experience!",
    publisher: {
      "@type": "Organization",
      name: "City2city",
    },
    sameAs: [
      "https://www.facebook.com/people/City2city/61556312991736",
      "https://twitter.com/city2citygroup",
      "https://www.pinterest.com/city2cityuk",
    ],
  };

  return <script type="application/ld+json">{JSON.stringify(jsonLd)}</script>;
};

export default StructuredData;
