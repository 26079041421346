import React, { useEffect, useState } from "react";
import styles from "./Drivers.module.css";
import { FiArrowLeft } from "react-icons/fi";
import chatIcon from "../../../../assets/Images/chatIcon.png";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Col, Row } from "react-bootstrap";
import starIcon from "../../../../assets/Images/starIcon.png";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import Loader from "../../../../utils/Loader/Loader";
import { STATUS_MSG } from "../../../../utils/StatusMsg";
import {
  acceptJob,
  fetchMasterDataList,
  getDriversLists,
  updateIsRedirect,
} from "../../../../store/slices/createJob";
import { STATUS_CODES } from "../../../../utils/StatusCode";
import Toster from "../../../../utils/Toaster";
// import { Icon } from "@iconify/react/dist/iconify.js";
import { Icon } from "@iconify/react";
import {
  userDetails,
  userLogoutClear,
} from "../../../../store/slices/UserSlice";

export default function HireDrivers() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const jobData = useSelector((state) => state.createJob);
  const { first, second, third, fourth, fifth } = useSelector(
    (state) => state.createJob.createJobData
  );

  const storedCoupon = useSelector((state) => state.createJob.selectedCoupon);

  const { userToken } = useSelector((state) => state.user);
  const [driversList, setDriversList] = useState([]);
  const [propertTypesHide, setPropertTypesHide] = useState([]);

  useEffect(() => {
    dispatch(fetchMasterDataList("property_types")).then((responseJson) => {
      const response = responseJson.payload;
      const responseMsg = response && response.data && response.data.message;
      const responseCode =
        STATUS_MSG[response && response.data && response.data.code];
      if (response && response.code === STATUS_CODES.SUCCESS) {
        const responseData = response.data.property_types;
        const staticTypes = responseData?.filter(
          (val) => val?.value_code == "static"
        );
        const staticNames = staticTypes?.map((item) => item?.value_name);
        setPropertTypesHide(staticNames);
        getDriversList();
        dispatch(userDetails(userToken)).then((response) => {});
      } else {
        if (
          response &&
          response.data &&
          response.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(responseMsg, "error");
        } else {
          Toster(t(responseCode), "error");
        }
      }
    });
  }, []);

  const isSendFloorType = (type) => {
    if (jobData.activeCategory == "mbk5ez") {
      if (type == "source") {
        if (
          first.propertTypesHide.includes(first.propertyType.sourceProperty.val)
        ) {
          return false;
        } else {
          return true;
        }
      }
      if (type == "destination") {
        if (
          first.propertTypesHide.includes(
            first.propertyType.destinationProperty.val
          )
        ) {
          return false;
        } else {
          return true;
        }
      }
    } else {
      return true;
    }
  };

  const getDriversList = () => {
    var mergedAllItems;

    if (third.isSubmit && second.isSubmit) {
      mergedAllItems = [...second.allItems, ...third?.customItems];
    } else if (third.isSubmit) {
      mergedAllItems = third?.customItems;
    } else if (second.isSubmit) {
      mergedAllItems = second.allItems;
    }

    const transformedData = mergedAllItems.map((obj) => ({
      category_id: obj.categoryId,
      item_id: obj.id,
      item_name: obj.name,
      quantity: obj.quantity,
      width: obj.width,
      height: obj.height,
      depth: obj.depth,
      unit_id: obj.unit.id,
      estimated_weight: obj.weight,
      estimated_weight_unit_id: obj.weightUnit.id,
      is_custom_item: obj.is_custom_item,
    }));

    const transformedStops = first.stops.map((obj) => ({
      location: obj.location,
      latitude: obj.lat,
      longitude: obj.lng,
    }));

    let requestData = new FormData();

    requestData.append("category_type_id", jobData.activeCategory);
    requestData.append("source_location", first.sourceLocation);
    requestData.append("source_latitude", first.source_latitude?.toFixed(6));
    requestData.append("source_longitude", first.source_longitude?.toFixed(6));

    {
      isSendFloorType("source")
        ? requestData.append("source_floor_id", first.sourceFloor.id)
        : requestData.append("source_floor_id", "");
    }
    requestData.append("is_source_lift", first.hasSourceLift);
    requestData.append("destination_location", first.destinationLocation);
    requestData.append(
      "destination_latitude",
      first.destination_latitude?.toFixed(6)
    );
    requestData.append(
      "destination_longitude",
      first.destination_longitude?.toFixed(6)
    );
    {
      isSendFloorType("destination")
        ? requestData.append("destination_floor_id", first.destinationFloor.id)
        : requestData.append("destination_floor_id", "");
    }
    requestData.append("is_destination_lift", first.hasDestinationLift);
    requestData.append("category_json", JSON.stringify(transformedData));
    requestData.append("add_stop", JSON.stringify(transformedStops));
    requestData.append("vehicle_type_id", fourth.selectedVehicle.id);
    requestData.append("helper_id", fifth.helper.id);
    requestData.append(
      "availability_date",
      dayjs(fifth.date).format("YYYY-MM-DD")
    );
    requestData.append(
      "availability_time",
      dayjs(fifth.time).format("HH:mm:ss")
    );
    requestData.append("is_packing", fifth.isPacking);
    requestData.append("estimated_time", fifth.estimatedJobTimeMinutes);
    requestData.append("total_distance", fifth.totalDistance);
    storedCoupon && requestData.append("coupon_code", storedCoupon.coupon_code);
    requestData.append(
      "source_property_type_id",
      first.propertyType.sourceProperty.id
    );
    requestData.append(
      "destination_property_type_id",
      first.propertyType.destinationProperty.id
    );

    dispatch(getDriversLists(requestData)).then((responseJson) => {
      const response = responseJson.payload;
      const responseMsg = response && response.data && response.data.message;
      const responseCode =
        STATUS_MSG[response && response.data && response.data.code];
      if (response && response.code === STATUS_CODES.SUCCESS) {
        setDriversList(response.data);
      } else {
        if (
          response &&
          response.data &&
          response.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(responseMsg, "error");
        } else {
          Toster(t(responseCode), "error");
        }
      }
    });
  };

  const onBack = () => {
    navigate("/job-summary");
  };

  const viewDriverDetails = (id) => {
    navigate(`/driver-details/${id}`);
  };

  const handelAcceptJob = (driverInfo) => {
    const id = driverInfo.id;
    const amount = driverInfo.amount;

    var mergedAllItems;

    if (third.isSubmit && second.isSubmit) {
      mergedAllItems = [...second.allItems, ...third?.customItems];
    } else if (third.isSubmit) {
      mergedAllItems = third?.customItems;
    } else if (second.isSubmit) {
      mergedAllItems = second.allItems;
    }

    const transformedData = mergedAllItems.map((obj) => ({
      category_id: obj.is_custom_item ? "" : obj.categoryId,
      item_id: obj.is_custom_item ? "" : obj.id,
      item_name: obj.name,
      quantity: obj.quantity,
      width: obj.width,
      height: obj.height,
      depth: obj.depth,
      unit_id: obj.unit.id,
      estimated_weight: obj.weight,
      estimated_weight_unit_id: obj.weightUnit.id,
      is_custom_item: obj.is_custom_item,
    }));

    const transformedStops = first.stops.map((obj) => ({
      location: obj.location,
      latitude: obj.lat,
      longitude: obj.lng,
    }));

    let requestData = new FormData();

    requestData.append("category_type_id", jobData.activeCategory);
    requestData.append("source_location", first.sourceLocation);
    requestData.append("source_latitude", first.source_latitude?.toFixed(6));
    requestData.append("source_longitude", first.source_longitude?.toFixed(6));
    requestData.append("source_city", first.source_city);
    requestData.append("source_postal_code", first.source_postalcode);
    // requestData.append("source_floor_id", first.sourceFloor.id);
    {
      isSendFloorType("source")
        ? requestData.append("source_floor_id", first.sourceFloor.id)
        : requestData.append("source_floor_id", "");
    }
    requestData.append("is_source_lift", first.hasSourceLift);
    requestData.append("destination_location", first.destinationLocation);
    requestData.append(
      "destination_latitude",
      first.destination_latitude?.toFixed(6)
    );
    requestData.append(
      "destination_longitude",
      first.destination_longitude?.toFixed(6)
    );
    requestData.append("destination_city", first.destination_city);
    requestData.append("destination_postal_code", first.destination_postalcode);
    // requestData.append("destination_floor_id", first.destinationFloor.id);
    {
      isSendFloorType("destination")
        ? requestData.append("destination_floor_id", first.destinationFloor.id)
        : requestData.append("destination_floor_id", "");
    }
    requestData.append("is_destination_lift", first.hasDestinationLift);
    requestData.append("add_stop", JSON.stringify(transformedStops));
    requestData.append("category_json", JSON.stringify(transformedData));
    requestData.append("vehicle_type_id", fourth.selectedVehicle.id);
    requestData.append("helper_id", fifth.helper.id);
    requestData.append(
      "availability_date",
      dayjs(fifth.date).format("YYYY-MM-DD")
    );
    requestData.append(
      "availability_time",
      dayjs(fifth.time).format("HH:mm:ss")
    );
    requestData.append("estimated_time", fifth.estimatedJobTimeMinutes);
    requestData.append("is_packing", fifth.isPacking);
    requestData.append("driver_id", id);
    requestData.append("amount", amount);
    requestData.append("total_distance", fifth.totalDistance);
    storedCoupon && requestData.append("coupon_code", storedCoupon.coupon_code);
    requestData.append(
      "source_property_type_id",
      first.propertyType.sourceProperty.id
    );
    requestData.append(
      "destination_property_type_id",
      first.propertyType.destinationProperty.id
    );

    const data = { requestData, userToken };

    dispatch(acceptJob(data)).then((responseJson) => {
      const response = responseJson.payload;
      const responseMsg = response && response.data && response.data.message;
      const responseCode =
        STATUS_MSG[response && response.data && response.data.code];
      if (response && response.code === STATUS_CODES.SUCCESS) {
        const data = {
          responseData: response.data,
          driverDetails: driverInfo,
        };
        navigate("/payment", {
          state: data,
        });
      } else {
        if (
          response &&
          response.data &&
          response.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(responseMsg, "error");
        } else if (
          response &&
          response.data &&
          response.data.code === STATUS_CODES.INVALID_TOKEN
        ) {
          dispatch(userLogoutClear());
          Toster(t("SESSION_EXPIRED"), "error");
          dispatch(updateIsRedirect(true));
          navigate("/login");
        } else {
          Toster(t(responseCode), "error");
        }
      }
    });
  };

  return (
    <>
      {jobData.isLoading && <Loader />}
      <div className="main">
        <div className="spaceTopManage">
          <Row className={styles.topHead}>
            <Col lg={5} md={5} sm={5} xs={3}>
              <div className="backarrow">
                <FiArrowLeft onClick={() => onBack()} />
              </div>
            </Col>
            <Col lg={7} md={7} sm={7} xs={9}>
              <div>
                <span className={styles.headTitle}>
                  {t("HIRE_YOUR_DRIVER")}
                </span>
              </div>
            </Col>
          </Row>
          {!jobData.isLoading && (
            <div className={styles.topContainer}>
              <div className={styles.subContainer}>
                <div className={styles.topSubHeadDesc}>
                  {driversList.length !== 0 && t("SELECT_BOOKING_DRIVER")}
                </div>
                <Row>
                  {driversList &&
                    driversList.map((driverInfo) => {
                      return (
                        <Col lg={3} md={4} sm={6} className="mb-3">
                          <div className={styles.topDriverCard}>
                            <div className={styles.driverRating}>
                              <div className={styles.driverBooked}>
                                <div className={styles.driverReviewRating}>
                                  {driverInfo.jobs_booked}
                                </div>
                                <div className={styles.driverReviewCount}>
                                  {t("JOBS_BOOKED")}
                                </div>
                              </div>
                              <div className={styles.driverReviewContainer}>
                                <div className={styles.driverReviewRating}>
                                  <div>
                                    {driverInfo &&
                                      Number(
                                        driverInfo.rating.average_rating
                                      )?.toFixed(1)}
                                  </div>
                                  <div>
                                    <Icon
                                      icon="material-symbols:star"
                                      color="#0F7DFF"
                                      width="22"
                                      height="22"
                                    />
                                  </div>
                                </div>
                                <div className={styles.driverReviewCount}>
                                  {driverInfo.rating.rating_count}{" "}
                                  {driverInfo.rating.rating_count > 1
                                    ? t("REVIEWS")
                                    : t("REVIEW")}
                                </div>
                              </div>
                            </div>
                            <div
                              className={styles.driverCard}
                              onClick={() => viewDriverDetails(driverInfo.id)}
                            >
                              <div>
                                <img
                                  src={driverInfo.image}
                                  className={styles.driverImage}
                                  alt="driver-image"
                                />
                              </div>
                              <div className={styles.driverName}>
                                {driverInfo.first_name} {driverInfo.last_name}
                              </div>
                              <div className={styles.driverTitle}>
                                {driverInfo?.email}
                              </div>
                              <div className={styles.vanType}>
                                {driverInfo.vehicle_name}
                              </div>
                              {driverInfo.discount !== 0 && (
                                <div className={styles.driverPriceCancel}>
                                  {driverInfo.currency_symbol}{" "}
                                  <strike>
                                    {driverInfo &&
                                      Number(driverInfo.amount)?.toFixed(2)}
                                  </strike>
                                </div>
                              )}
                              <div className={styles.driverPrice}>
                                {driverInfo.currency_symbol}{" "}
                                {driverInfo &&
                                  Number(driverInfo.total_amount)?.toFixed(2)}
                              </div>
                              <div className={styles.driverExtraHours}>
                                For the first{" "}
                                <strong>
                                  {fifth.estimatedJobTime.hour}:
                                  {Number(
                                    fifth.estimatedJobTime.min
                                  )?.toFixed()}{" "}
                                  hours
                                </strong>{" "}
                                and then{" "}
                                <strong>
                                  {driverInfo.currency_symbol}
                                  {driverInfo.extra_per_hours_price}
                                </strong>{" "}
                                per hour
                              </div>
                            </div>
                            <div className={styles.driverAccept}>
                              <Button
                                onClick={() => handelAcceptJob(driverInfo)}
                              >
                                {t("ACCEPT")}
                              </Button>
                            </div>
                          </div>
                        </Col>
                      );
                    })}
                </Row>
                <Row>
                  {[...Array(0)].map((driverInfo) => {
                    return (
                      <Col lg={3} md={4} sm={6} className="mb-3">
                        <div className={styles.topDriverCard}>
                          <div className={styles.driverRating}>
                            <div>4.6</div>
                            <div>
                              <img src={starIcon} alt="star-icon" />
                            </div>
                          </div>
                          <div
                            className={styles.driverCard}
                            onClick={() => viewDriverDetails()}
                          >
                            <div>
                              {/* <img src={driverImg} alt="driver-image" /> */}
                            </div>
                            <div className={styles.driverName}>
                              {"Jeremy Renner"}
                            </div>
                            <div className={styles.driverTitle}>
                              {"Lorem Ipsum is simply Dummy Text."}
                            </div>
                            <div className={styles.driverPrice}>
                              {"$252.00"}
                            </div>
                          </div>
                          <div className={styles.driverAccept}>
                            <Button>{t("ACCEPT")}</Button>
                          </div>
                        </div>
                      </Col>
                    );
                  })}
                </Row>
                {!jobData.isLoading && driversList.length == 0 && (
                  <Row>
                    <div className={styles.notFoundContainer}>
                      <div className={styles.notFoundIcon}>
                        <Icon
                          icon="lucide:search-x"
                          color="grey"
                          width="100"
                          height="100"
                        />
                      </div>
                      <div className={styles.notFoundMessage}>
                        {t("DRIVERS_NOT_FOUND")}
                      </div>
                    </div>
                  </Row>
                )}
              </div>
              {/* <div className={styles.nextBtnFooter}>
                <div className={styles.chatFeature}>
                  <img src={chatIcon} alt="chat-image" />
                </div>
              </div> */}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
