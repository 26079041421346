import React, { useEffect, useState } from "react";
import "../../assets/Styles/Common.css";
import styles from "./Jobs.module.css";
import { useTranslation } from "react-i18next";
import { Col, Container, Form, Row, Tab, Tabs } from "react-bootstrap";
import { FiSearch } from "react-icons/fi";
import options from "../../assets/DriverImages/options.svg";
import SublyApi from "../../helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../utils/Loader/Loader";
import { STATUS_MSG } from "../../utils/StatusMsg";
import { STATUS_CODES } from "../../utils/StatusCode";
import Toster from "../../utils/Toaster";
import Filter from "./Modal/Filter";
import dayjs from "dayjs";
import { userDetails, userLogoutClear } from "../../store/slices/UserSlice";
import chatIcon from "../../assets/Images/chatIcon.png";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useNavigate } from "react-router-dom";

export default function Jobs() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userToken } = useSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(false);
  const [jobList, setJobList] = useState([]);
  const [type, setType] = useState("current");
  const [date, setDate] = useState();
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [pageNo, setPageNo] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const [filterModal, setFilterModal] = useState({ show: false });

  const handleFilter = () => {
    setFilterModal((pre) => ({ ...pre, show: true }));
  };

  const getProfileDetails = () => {
    dispatch(userDetails(userToken)).then((responsejson) => {
      const response = responsejson.payload;
      const msg = response && response.data && response.data.message;
      const ResponseCode =
        STATUS_MSG[response && response.data && response.data.code];
      if (response && response.code === STATUS_CODES.SUCCESS) {
      } else if (
        response &&
        response.data &&
        response.data.code === STATUS_CODES.INVALID_TOKEN
      ) {
        dispatch(userLogoutClear());
        navigate("/");
      } else {
        if (
          response &&
          response.data &&
          response.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  useEffect(() => {
    getProfileDetails();
  }, []);

  const getJobLists = (value = "") => {
    setIsLoading(true);
    const params = {
      type,
      date: type == "pending" && date ? dayjs(date).format("YYYY-MM-DD") : "",
      category_ids: selectedCategories.join(","),
      page_no: pageNo,
      search: value,
    };

    let res = SublyApi.getJobList(params, userToken);
    res.then((response) => {
      const responseMsg = response && response.data && response.data.message;
      const responseCode =
        STATUS_MSG[response && response.data && response.data.code];
      setIsLoading(false);
      if (response.code === STATUS_CODES.SUCCESS) {
        setJobList(response.data.jobList);
      } else {
        if (
          response.code == STATUS_CODES.SERVER_VALIDATION ||
          response.data.code == STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(responseMsg, "error");
        }
        if (
          response.code == STATUS_CODES.INVALID_TOKEN ||
          response.data.code == STATUS_CODES.INVALID_TOKEN
        ) {
          Toster(t(responseCode), "error");
          dispatch(userLogoutClear());
        } else {
          Toster(t(responseCode), "error");
        }
      }
    });
    res.catch(() => {
      setIsLoading(false);
    });
  };

  useEffect(() => {
    getJobLists();
  }, [type]);

  useEffect(() => {
    const getFilterdData = setTimeout(() => {
      setIsLoading(true);
      const params = {
        type,
        date: date ? dayjs(date).format("YYYY-MM-DD") : "",
        category_ids: selectedCategories.join(","),
        page_no: pageNo,
        search: searchKey,
      };

      let res = SublyApi.getJobList(params, userToken);
      res.then((response) => {
        const responseMsg = response && response.data && response.data.message;
        const responseCode =
          STATUS_MSG[response && response.data && response.data.code];
        setIsLoading(false);
        if (response.code === STATUS_CODES.SUCCESS) {
          setJobList(response.data.jobList);
        } else {
          if (
            response.code == STATUS_CODES.SERVER_VALIDATION ||
            response.data.code == STATUS_CODES.SERVER_VALIDATION
          ) {
            Toster(responseMsg, "error");
          }
          if (
            response.code == STATUS_CODES.INVALID_TOKEN ||
            response.data.code == STATUS_CODES.INVALID_TOKEN
          ) {
            Toster(t(responseCode), "error");
            dispatch(userLogoutClear());
          } else {
            Toster(t(responseCode), "error");
          }
        }
      });
      res.catch(() => {
        setIsLoading(false);
      });
    }, 1000);

    return () => clearTimeout(getFilterdData);
  }, [searchKey]);

  const handleTabSelect = (key) => {
    setType(key);
    setSearchKey("");
  };

  const handleFilterApply = () => {
    getJobLists();
  };

  const handleViewDetails = (jobId) => {
    navigate(`/job-details/${jobId}`);
  };

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearchKey(value);
  };

  return (
    <>
      {isLoading && <Loader />}
      <div className="main">
        <div className="spaceTopManage">
          <div className={styles.headContainer}>Jobs</div>
          <div className={styles.tabsContainer}>
            <Tabs
              defaultActiveKey="current"
              className="jobTabs"
              onSelect={handleTabSelect}
            >
              <Tab eventKey="pending" title={t("Pending Job")}></Tab>
              <Tab eventKey="current" title={t("Current Job")}></Tab>
            </Tabs>
          </div>
          <div className={styles.jobSearchBox}>
            <span>
              <Form.Group className="mb-3 jobSearchSection">
                <Form.Control
                  type="text"
                  className={styles.jobSearchInput}
                  placeholder={t("Search")}
                  value={searchKey}
                  onChange={(e) => handleSearchChange(e)}
                />
              </Form.Group>
              <FiSearch />
              <img src={options} alt="svg-img" onClick={() => handleFilter()} />
            </span>
          </div>
          <div>
            <Container>
              <Row className={styles.topWrapper}>
                {jobList &&
                  jobList.map((jobInfo) => {
                    return (
                      <Col lg={4} md={6} sm={12} className="mb-3">
                        <div
                          className={styles.topMainContainer}
                          onClick={() => handleViewDetails(jobInfo.id)}
                        >
                          <div className={styles.locationContainer}>
                            <div className={styles.viaShow}>
                              <span className={styles.availabilityTitle}>
                                {t("VIA")} :{" "}
                              </span>
                              <span className={styles.stopsValue}>
                                {jobInfo.stopCount ? jobInfo.stopCount : 0}
                              </span>
                            </div>
                            <div className={styles.addressRouteWrapper}>
                              <div className={styles.subLocationInner}>
                                <div className={styles.routeLinePath}>
                                  <div>
                                    <Icon
                                      icon="fluent:location-arrow-up-16-regular"
                                      height="22"
                                      color="#828282"
                                    />
                                  </div>
                                  <div className={styles.leftRouteLine}></div>
                                  <div>
                                    <Icon
                                      icon="fluent:location-arrow-up-16-regular"
                                      vFlip
                                      height="22"
                                      color="#828282"
                                    />
                                  </div>
                                </div>
                                <div className={styles.subLocationContainer}>
                                  <div>
                                    <div className={styles.subTitleName}>
                                      {t("COLLECT_ADDRESS")}
                                    </div>
                                    <div className={styles.titleDescription}>
                                      {jobInfo.source_city &&
                                      jobInfo.source_postal_code
                                        ? `${jobInfo.source_city} ${jobInfo.source_postal_code}`
                                        : jobInfo.source_city ||
                                          jobInfo.source_location}
                                    </div>
                                  </div>
                                  <div>
                                    <div className={styles.subTitleName}>
                                      {t("DELIVER_ADDRESS")}
                                    </div>
                                    <div className={styles.titleDescription}>
                                      {jobInfo.destination_city &&
                                      jobInfo.destination_postal_code
                                        ? `${jobInfo.destination_city} ${jobInfo.destination_postal_code}`
                                        : jobInfo.destination_city ||
                                          jobInfo.destination_location}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className={styles.infoContainer}>
                            <div className={styles.infoAvailabilty}>
                              <div>
                                <span className={styles.availabilityTitle}>
                                  Availability -{" "}
                                </span>
                                <span className={styles.availabilityValue}>
                                  {dayjs(jobInfo.availability_date).format(
                                    "DD MMM YYYY"
                                  )}
                                  ,{" "}
                                  {dayjs(
                                    jobInfo.availability_time,
                                    "HH:mm:ss"
                                  ).format("hh:mmA")}
                                </span>
                              </div>
                            </div>
                            <hr className={styles.hrSection} />
                            <div className={styles.infoCategory}>
                              <div>
                                <span className={styles.categoryName}>
                                  {jobInfo.category_name}
                                </span>
                              </div>
                            </div>
                            <hr className={styles.hrSection} />
                            <div className={styles.infoHelper}>
                              <div>
                                <div>
                                  <span className={styles.availabilityTitle}>
                                    {t("HELPER")} :{" "}
                                  </span>
                                  <span className={styles.categoryName}>
                                    {jobInfo.helper}
                                  </span>
                                </div>
                              </div>
                              <div>
                                <span className={styles.availabilityTitle}>
                                  {t("FLOOR")} :{" "}
                                </span>
                                <span className={styles.categoryName}>
                                  {jobInfo.floors <= 8
                                    ? jobInfo.floors
                                    : t("ABOVE_8TH_FLOOR")}
                                </span>
                              </div>
                            </div>
                            <hr className={styles.hrSection} />
                            <div>
                              <span className={styles.vehicleType}>
                                {jobInfo.vehicle_type_name}
                              </span>
                            </div>
                          </div>
                        </div>
                      </Col>
                    );
                  })}
              </Row>
              {!isLoading && jobList.length == 0 && (
                <Row>
                  <div className={styles.notFoundContainer}>
                    <div className={styles.notFoundIcon}>
                      <Icon
                        icon="lucide:search-x"
                        color="grey"
                        width="100"
                        height="100"
                      />
                    </div>
                    <div className={styles.notFoundMessage}>
                      {t("Jobs not found, please try again!")}
                    </div>
                  </div>
                </Row>
              )}
            </Container>
          </div>
        </div>
        <div className={styles.chatFeature}>
          {/* <img src={chatIcon} alt="chat-image" /> */}
        </div>
      </div>
      <Filter
        filterModal={filterModal}
        setFilterModal={setFilterModal}
        type={type}
        date={date}
        setDate={setDate}
        selectedCategories={selectedCategories}
        setSelectedCategories={setSelectedCategories}
        handleFilterApply={handleFilterApply}
        setSearchKey={setSearchKey}
      />
    </>
  );
}
