import React, { useEffect, useState } from "react";
import SublyApi from "../../helpers/Api";
import { STATUS_MSG } from "../../utils/StatusMsg";
import { STATUS_CODES } from "../../utils/StatusCode";
import Toster from "../../utils/Toaster";
import { useTranslation } from "react-i18next";
import { Accordion, Container, Row } from "react-bootstrap";
import styles from "./Faq.module.css";
import Loader from "../../utils/Loader/Loader";
import { Icon } from "@iconify/react/dist/iconify.js";
import { Helmet } from "react-helmet-async";

const Faq = () => {
  const { t } = useTranslation();
  const [faqList, setFaqList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getFaqList = () => {
    setIsLoading(true);
    let res = SublyApi.fetchCustomersFaq();
    res.then((response) => {
      const responseMsg = response && response.data && response.data.message;
      const responseCode =
        STATUS_MSG[response && response.data && response.data.code];
      setIsLoading(false);
      if (response.code === STATUS_CODES.SUCCESS) {
        setFaqList(response.data);
      } else {
        if (
          response.code == STATUS_CODES.SERVER_VALIDATION ||
          response.data.code == STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(responseMsg, "error");
        } else {
          Toster(t(responseCode), "error");
        }
      }
    });
    res.catch(() => {
      setIsLoading(false);
    });
  };

  useEffect(() => {
    getFaqList();
  }, []);

  return (
    <>
      <Helmet>
        <title>
          City2City | FAQs – House Removals, Van Hire & Moving Services
        </title>
        <meta
          name="description"
          content="City2City - Got questions? Our FAQs cover house removals, van removals, man and van service, same-day removals, packing and unpacking, and cost-effective removals. Read more."
        />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <div className="main">
        <div className="spaceTopManage"></div>
        {isLoading && <Loader />}
        {faqList.length !== 0 && (
          <div className={styles.faqContainer}>
            {faqList.map((list) => {
              return (
                <div className={styles.faqLists}>
                  <Container>
                    <Accordion className="faqAccordian">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>{list.question}</Accordion.Header>
                        <Accordion.Body>{list.answer}</Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Container>
                </div>
              );
            })}
          </div>
        )}
        {!isLoading && faqList.length == 0 && (
          <Row>
            <div className={styles.notFoundContainer}>
              <div>
                <Icon
                  icon="lucide:search-x"
                  color="grey"
                  width="100"
                  height="100"
                />
              </div>
              <div className={styles.notFoundMessage}>{t("FAQ_NOT_FOUND")}</div>
            </div>
          </Row>
        )}
      </div>
    </>
  );
};

export default Faq;
