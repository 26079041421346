import React from "react";
import { Link } from "react-router-dom";
import styles from "./NotFound.module.css";

const NotFound = () => {
  return (
    <div className={styles.nofoundcontainer}>
      <h1>404</h1>
      <h2>Oops! Page Not Found</h2>
      <p>
        Sorry but the page you are looking for does not exist, have been
        removed, name changed or it's temporarily unavailable.
      </p>
      <Link to="/" className={styles.homeButton}>
        Back to home
      </Link>
    </div>
  );
};

export default NotFound;
