import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import HomePage from "../appComponents/Homepage/Hompage";
// import Footer from "../appComponents/Footer/Footer";
import Login from "../authComponent/Login/Login";
import ScrollToTop from "../helpers/ScrollToTop";
import ForgotPassword from "../authComponent/ForgotPassword/ForgotPassword";
import { useSelector } from "react-redux";
import SignUp from "../authComponent/SignUp/SignUp";
import ResetPassword from "../authComponent/ResetPassword/ResetPassword";
import Furnitures from "../appComponents/CreateJob/Furnitures/Furniture";
import JobSummary from "../appComponents/CreateJob/Furnitures/jobSummary";
import HireDrivers from "../appComponents/CreateJob/Furnitures/Drivers/hireDrivers";
import DriverDetails from "../appComponents/CreateJob/Furnitures/Drivers/DriverDetails";
import Payments from "../appComponents/CreateJob/Furnitures/Payments/Payments";
import Profile from "../appComponents/Profile/Profile";
import DriverLogin from "../DRIVERSIDE/authComponent/DriverLogin/DriverLogin";
import SignupFlow from "../DRIVERSIDE/authComponent/DriverSignUp/SignupFlow";
import DriverForgotPassword from "../DRIVERSIDE/authComponent/DriverForgotPassword/DriverForgotPassword";
import DriverResetPassword from "../DRIVERSIDE/authComponent/DriverResetPassword/DriverResetPassword";
import DriverProfile from "../DRIVERSIDE/appComponents/DriverProfile/DriverProfile";
import DriverHome from "../DRIVERSIDE/appComponents/DriverHome/DriverHome";
import ChangePassword from "../DRIVERSIDE/appComponents/ChangePassword/ChangePassword";
import BankAccount from "../DRIVERSIDE/appComponents/BankAccount/BankAccount";
import PriceSettings from "../DRIVERSIDE/appComponents/PriceSetting/PriceSettings";
import RadiusSet from "../DRIVERSIDE/appComponents/RadiusSet/RadiusSet";
import Services from "../DRIVERSIDE/appComponents/Services/Services";
import Schedule from "../DRIVERSIDE/appComponents/Schedule/Schedule";
import Jobs from "../appComponents/Jobs/Jobs";
import JobDetails from "../appComponents/Jobs/JobDetails";
import JobTracking from "../appComponents/Jobs/JobTracking";
import JobHistory from "../appComponents/Jobs/JobHistory";
import Header from "../appComponents/Header/Header";
import DriverHeader from "../DRIVERSIDE/appComponents/DriverHeader/DriverHeader";
import DriverJobDetails from "../DRIVERSIDE/appComponents/JobOffers/DriverJobDetails";
import DriverJobHistory from "../DRIVERSIDE/appComponents/JobHistory/JobHistory";
import ChangePasswordCustomer from "../appComponents/ChangePassword/ChangePasswordCustomer";
import Support from "../appComponents/Support/Support";
import DriverSupport from "../DRIVERSIDE/appComponents/Support/DriverSupport";
import Tutorial from "../DRIVERSIDE/appComponents/Tutorial/Tutorial";
import TutoriaVideos from "../DRIVERSIDE/appComponents/Tutorial/TutoriaVideo";
import PrivacyPolicy from "../appComponents/Privacy Policy/PrivacyPolicy";
import TermsConditions from "../appComponents/TermsConditions/TermsConditions";
import AboutUs from "../appComponents/AboutUs/AboutUs";
// import AbousUs from "../DRIVERSIDE/appComponents/AboutUs/DriverAbousUs";
import DriverTermsCondition from "../DRIVERSIDE/appComponents/TermsCondition/DriverTermsCondition";
import DriverPrivacyPolicy from "../DRIVERSIDE/appComponents/PrivacyPolicy/DriverPrivacyPolicy";
import DriverAbousUs from "../DRIVERSIDE/appComponents/AboutUs/DriverAbousUs";
import CrispChat from "../helpers/CrispChat";
// import FireBaseNotification from "../firebase/firebaseNotification";
import SaveCard from "../appComponents/SaveCards/SaveCard";
import Faq from "../appComponents/FAQ/Faq";
import Info from "../appComponents/Info/Info";
import FaqDriver from "../DRIVERSIDE/appComponents/FAQ/FaqDriver";
import Packing from "../DRIVERSIDE/appComponents/Packing/Packing";
import VanGuide from "../appComponents/VanGuide/VanGuide";
import NotFound from "../commonComponents/NotFound";
import { Suspense } from "react";

function Routers() {
  const isAuthenticated = useSelector((state) => state.user.userToken);
  const userDetail = useSelector((state) => state.user.currentUser);
  const usertype = useSelector((state) => state.user.userType);
  const { fifth } = useSelector((state) => state.createJob.createJobData);

  const PublicRoute = ({ children }) => {
    if (usertype == "customer") {
      return !isAuthenticated ? children : <Navigate to="/profile" />;
    } else {
      return !isAuthenticated ? children : <Navigate to="/driver-profile" />;
    }
  };

  const PrivateRoute = ({ children }) => {
    if (usertype == "customer") {
      return isAuthenticated ? children : <Navigate to="/login" />;
    } else {
      return <Navigate to="/driver-login" />;
    }
  };

  const HasJobData = ({ children }) => {
    if (!fifth.hasOwnProperty("isSubmit")) {
      return <Navigate to="/create-job" />;
    } else {
      return children;
    }
  };

  const DriverPublicRoute = ({ children }) => {
    if (usertype == "driver") {
      return !isAuthenticated ? children : <Navigate to="/driver-profile" />;
    } else {
      return !isAuthenticated ? children : <Navigate to="/profile" />;
    }
  };

  const DriverPrivateRoute = ({ children }) => {
    if (usertype == "driver") {
      return isAuthenticated ? children : <Navigate to="/driver-login" />;
    } else {
      return <Navigate to="/driver-login" />;
    }
  };

  const CustomerLayout = ({ children }) => {
    return (
      <div>
        <Header children={children} />
      </div>
    );
  };

  const DriverLayout = ({ children }) => {
    return (
      <div>
        <DriverHeader children={children} />
      </div>
    );
  };

  return (
    <>
      <Router basename={"/"}>
        {/* <FireBaseNotification /> */}
        <ScrollToTop />
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route
              exact
              path="/"
              element={
                <PublicRoute>
                  {<CustomerLayout>{<HomePage />}</CustomerLayout>}
                </PublicRoute>
              }
            />
            <Route
              exact
              path="/login"
              element={
                <PublicRoute>
                  {<CustomerLayout>{<Login />}</CustomerLayout>}
                </PublicRoute>
              }
            />
            <Route
              exact
              path="/sign-up"
              element={
                <PublicRoute>
                  {<CustomerLayout>{<SignUp />}</CustomerLayout>}
                </PublicRoute>
              }
            />
            <Route
              exact
              path="/forgot-password"
              element={
                <PublicRoute>
                  {<CustomerLayout>{<ForgotPassword />}</CustomerLayout>}
                </PublicRoute>
              }
            />
            <Route
              exact
              path="/reset-password"
              element={
                <PublicRoute>
                  {<CustomerLayout>{<ResetPassword />}</CustomerLayout>}
                </PublicRoute>
              }
            />
            <Route
              exact
              path="/profile"
              element={
                <PrivateRoute>
                  {<CustomerLayout>{<Profile />}</CustomerLayout>}
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/faq"
              element={<CustomerLayout>{<Faq />}</CustomerLayout>}
            />
            <Route
              exact
              path="/van-guide"
              element={<CustomerLayout>{<VanGuide />}</CustomerLayout>}
            />
            <Route exact path="/create-job" element={<Furnitures />} />
            <Route
              exact
              path="/job-summary"
              element={
                <HasJobData>
                  {<CustomerLayout>{<JobSummary />}</CustomerLayout>}
                </HasJobData>
              }
            />
            <Route
              exact
              path="/hire-drivers"
              element={
                <HasJobData>
                  {<CustomerLayout>{<HireDrivers />}</CustomerLayout>}
                </HasJobData>
              }
            />
            <Route
              exact
              path="/driver-details/:id"
              element={
                <HasJobData>
                  {<CustomerLayout>{<DriverDetails />}</CustomerLayout>}
                </HasJobData>
              }
            />
            <Route
              exact
              path="/payment"
              element={<CustomerLayout>{<Payments />}</CustomerLayout>}
            />
            <Route
              exact
              path="/jobs"
              element={
                <PrivateRoute>
                  {<CustomerLayout>{<Jobs />}</CustomerLayout>}
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/job-details/:jobId"
              element={
                <PrivateRoute>
                  {<CustomerLayout>{<JobDetails />}</CustomerLayout>}
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/job-tracking/:jobId"
              element={
                <PrivateRoute>
                  {<CustomerLayout>{<JobTracking />}</CustomerLayout>}
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/job-history"
              element={
                <PrivateRoute>
                  {<CustomerLayout>{<JobHistory />}</CustomerLayout>}
                </PrivateRoute>
              }
            />

            <Route
              exact
              path="/change-password"
              element={
                <PrivateRoute>
                  {
                    <CustomerLayout>
                      {<ChangePasswordCustomer />}
                    </CustomerLayout>
                  }
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/save-cards"
              element={
                <PrivateRoute>
                  {<CustomerLayout>{<SaveCard />}</CustomerLayout>}
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/support"
              element={
                <PrivateRoute>
                  {<CustomerLayout>{<Support />}</CustomerLayout>}
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/about-us"
              element={
                <PrivateRoute>
                  {<CustomerLayout>{<AboutUs />}</CustomerLayout>}
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/privacy-policy"
              element={
                <PrivateRoute>
                  {<CustomerLayout>{<PrivacyPolicy />}</CustomerLayout>}
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/terms-conditions"
              element={
                <PrivateRoute>
                  {<CustomerLayout>{<TermsConditions />}</CustomerLayout>}
                </PrivateRoute>
              }
            />
            <Route exact path="/privacy-and-policy" element={<Info />} />
            <Route exact path="/terms-and-conditions" element={<Info />} />

            {/* driver side routing */}
            <Route
              exact
              path="/driver-login"
              element={
                <DriverPublicRoute>
                  {<DriverLayout>{<DriverLogin />}</DriverLayout>}
                </DriverPublicRoute>
              }
            />
            <Route
              exact
              path="/driver-sign-up"
              element={
                <DriverPublicRoute>
                  {<DriverLayout>{<SignupFlow />}</DriverLayout>}
                </DriverPublicRoute>
              }
            />
            <Route
              exact
              path="/driver-forgot-password"
              element={
                <DriverPublicRoute>
                  {<DriverLayout>{<DriverForgotPassword />}</DriverLayout>}
                </DriverPublicRoute>
              }
            />
            <Route
              exact
              path="/driver-reset-password"
              element={
                <DriverPublicRoute>
                  {<DriverLayout>{<DriverResetPassword />}</DriverLayout>}
                </DriverPublicRoute>
              }
            />
            <Route
              exact
              path="/faq-driver"
              element={<DriverLayout>{<FaqDriver />}</DriverLayout>}
            />
            <Route
              exact
              path="/driver-van-guide"
              element={<DriverLayout>{<VanGuide />}</DriverLayout>}
            />
            <Route
              exact
              path="/driver-profile"
              element={
                <DriverPrivateRoute>
                  {<DriverLayout>{<DriverProfile />}</DriverLayout>}
                </DriverPrivateRoute>
              }
            />
            <Route
              exact
              path="/driver-home"
              element={
                <DriverPrivateRoute>
                  {<DriverLayout>{<DriverHome />}</DriverLayout>}
                </DriverPrivateRoute>
              }
            />
            <Route
              exact
              path="/driver-change-password"
              element={
                <DriverPrivateRoute>
                  {<DriverLayout>{<ChangePassword />}</DriverLayout>}
                </DriverPrivateRoute>
              }
            />
            <Route
              exact
              path="/driver-bank-account"
              element={
                <DriverPrivateRoute>
                  {<DriverLayout>{<BankAccount />}</DriverLayout>}
                </DriverPrivateRoute>
              }
            />
            <Route
              exact
              path="/driver-price"
              element={
                <DriverPrivateRoute>
                  {<DriverLayout>{<PriceSettings />}</DriverLayout>}
                </DriverPrivateRoute>
              }
            />
            <Route
              exact
              path="/driver-radius"
              element={
                <DriverPrivateRoute>
                  {<DriverLayout>{<RadiusSet />}</DriverLayout>}
                </DriverPrivateRoute>
              }
            />
            <Route
              exact
              path="/service"
              element={
                <DriverPrivateRoute>
                  {<DriverLayout>{<Services />}</DriverLayout>}
                </DriverPrivateRoute>
              }
            />
            <Route
              exact
              path="/packing"
              element={
                <DriverPrivateRoute>
                  {<DriverLayout>{<Packing />}</DriverLayout>}
                </DriverPrivateRoute>
              }
            />
            <Route
              exact
              path="/driver-tutorial"
              element={
                <DriverPrivateRoute>
                  {<DriverLayout>{<Tutorial />}</DriverLayout>}
                </DriverPrivateRoute>
              }
            />
            <Route
              exact
              path="/tutoria-videos/:TutorialId"
              element={
                <DriverPrivateRoute>
                  {<DriverLayout>{<TutoriaVideos />}</DriverLayout>}
                </DriverPrivateRoute>
              }
            />
            <Route
              exact
              path="/driver-support"
              element={
                <DriverPrivateRoute>
                  {<DriverLayout>{<DriverSupport />}</DriverLayout>}
                </DriverPrivateRoute>
              }
            />
            <Route
              exact
              path="/schedule"
              element={
                <DriverPrivateRoute>
                  {<DriverLayout>{<Schedule />}</DriverLayout>}
                </DriverPrivateRoute>
              }
            />
            <Route
              exact
              path="/driver-job-details/:jobId"
              element={
                <DriverPrivateRoute>
                  {<DriverLayout>{<DriverJobDetails />}</DriverLayout>}
                </DriverPrivateRoute>
              }
            />
            <Route
              exact
              path="/driver-job-history"
              element={
                <DriverPrivateRoute>
                  {<DriverLayout>{<DriverJobHistory />}</DriverLayout>}
                </DriverPrivateRoute>
              }
            />

            <Route
              exact
              path="/driver-about-us"
              element={
                <DriverPrivateRoute>
                  {<DriverLayout>{<DriverAbousUs />}</DriverLayout>}
                </DriverPrivateRoute>
              }
            />

            <Route
              exact
              path="/driver-privacy-policy"
              element={
                <DriverPrivateRoute>
                  {<DriverLayout>{<DriverPrivacyPolicy />}</DriverLayout>}
                </DriverPrivateRoute>
              }
            />

            <Route
              exact
              path="/driver-terms-conditions"
              element={
                <DriverPrivateRoute>
                  {<DriverLayout>{<DriverTermsCondition />}</DriverLayout>}
                </DriverPrivateRoute>
              }
            />

            {/* Catch-all route for 404 not found */}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      </Router>
      <CrispChat />
    </>
  );
}
export default Routers;
