import { Container, Form } from "react-bootstrap";
import backgroundImage from "../../../assets/DriverImages/Bg.webp";
import "../../../assets/Styles/DriverCommon.css";
import styles from "../DriverSignUp/DriverSignUp.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { sanitizeHtmlTags } from "../../../utils/sanitizeHtmlTags";
import { FiArrowLeft } from "react-icons/fi";
import { TiArrowSortedDown } from "react-icons/ti";
import { useEffect, useState } from "react";
import VehicletColorModal from "./VehicleColor";
import VehicleTypeModal from "./Vehicletype";
import { CiCamera } from "react-icons/ci";
import uploadImg from "../../../assets/DriverImages/uploadIcon.png";
import { MdCancel } from "react-icons/md";
import { setSignupFormData } from "../../../store/slices/UserSlice";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import Toster from "../../../utils/Toaster";

//---------function for signUp second step form----------
function SignUpSecondForm({ setNextStep, previousStep, setPreviousStep }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    setValue,
    control,
    clearErrors,
    formState: { errors },
  } = useForm();

  const { signupFormData, nextStep } = useSelector((state) => state.user);
  const [vehicleTypeModal, setVehicleTypeModal] = useState(false);
  const [vehicleColorModal, setVehicleColorModal] = useState(false);
  const [vehicleImage, setVehicleImage] = useState(
    signupFormData.vehicleImage ? signupFormData.vehicleImage : null
  );
  const [selectVehicleColor, setSelectVehicleColor] = useState("");
  const [selectVehicle, setSelectVehicle] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [formatedDate, setFormatedDate] = useState("");

  const currentDate = new Date();
  const maxDate = new Date(currentDate); // Create a new date object based on the current date
  // maxDate.setFullYear(currentDate.getFullYear() + 1);
  maxDate.setFullYear(currentDate.getFullYear());

  // function for apply validation in year field
  const validateYear = () => {
    if (!selectedYear) {
      return `${t("VEHICLE_YEAR_REQUIRED")}.`;
    }
    return true;
  };

  const handleDateChange = (date) => {
    setSelectedYear(date);
    if (date) {
      const formattedYear = dayjs(date).format("YYYY");

      setFormatedDate(formattedYear);
    } else {
      setFormatedDate("");
    }
  };

  // function for apply validation in image field
  const validateImage = () => {
    if (!vehicleImage) {
      return `${t("VEHICLE_PHOTO_REQUIRED")}`;
    }
    return true;
  };

  //----- function for Upload image-----
  const onImageChange = async (e) => {
    if (e.target.files.length !== 0) {
      const file = e.target.files[0];
      const fileSizeInMB = (file.size / (1024 * 1024)).toFixed(2);
      if (fileSizeInMB > 5) {
        Toster(t("FILE_SIZE_LARGE"), "error");
        return;
      }
      if (file) {
        const base64String = await convertFileToBase64(file);
        setVehicleImage(base64String);
      }
    }
  };

  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  //----- function for remove image-----
  function onImageRemove() {
    setVehicleImage("");
  }

  // disable space on key press input
  const handleKeyPress = (e, t) => {
    if (e.target.value == " ") setValue(e.target.name, e.target.value.trim());
  };

  // --------function for show prefilled formdata--------
  useEffect(() => {
    if (previousStep == true) {
      setValue(
        "LicencePlateNumber",
        signupFormData.LicencePlateNumber
          ? signupFormData.LicencePlateNumber
          : ""
      );
      setValue(
        "vehicleColor",
        (signupFormData.selectVehicleColor &&
          signupFormData.selectVehicleColor.value_name) ||
          ""
      );
      setSelectVehicleColor({
        id:
          (signupFormData.selectVehicleColor &&
            signupFormData.selectVehicleColor.id) ||
          "",
        value_name:
          (signupFormData.selectVehicleColor &&
            signupFormData.selectVehicleColor.value_name) ||
          "",
      });
      setValue(
        "vanType",
        (signupFormData.selectVehicle &&
          signupFormData.selectVehicle.vehicle_name) ||
          ""
      );
      setSelectVehicle({
        id:
          (signupFormData.selectVehicle && signupFormData.selectVehicle.id) ||
          "",
        vehicle_name:
          (signupFormData.selectVehicle &&
            signupFormData.selectVehicle.vehicle_name) ||
          "",
      });

      setSelectedYear(signupFormData && signupFormData.selectedYear);
      setFormatedDate(signupFormData && signupFormData.selectedYear);

      setValue(
        "vehicleDescription",
        signupFormData.vehicleDescription
          ? signupFormData.vehicleDescription
          : ""
      );
    }
  }, [nextStep, previousStep]);

  // --------function for submit formdata--------
  const onSubmit = async (formdata) => {
    let userData = {
      ...formdata,
      selectVehicleColor: {
        id: selectVehicleColor && selectVehicleColor.id,
        value_name: selectVehicleColor && selectVehicleColor.value_name,
      },
      selectVehicle: {
        id: selectVehicle && selectVehicle.id,
        vehicle_name: selectVehicle && selectVehicle.vehicle_name,
      },
      vehicleImage: vehicleImage ? vehicleImage : "",
      selectedYear: formatedDate ? formatedDate : "",
    };
    dispatch(setSignupFormData(userData));
    setNextStep(2);
  };

  return (
    <div className="main">
      <div className="spaceTopManageDriver">
        <div
          style={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: "cover",
            width: "100%",
            padding: "20px 0px",
            backgroundPositionX: "center",
          }}
        >
          <Container>
            <div className={styles.formBlocks}>
              <div className={styles.signPageCls}>
                <span>
                  <strong>2</strong>/4
                </span>
                <div className={styles.signPageArrow}>
                  <FiArrowLeft
                    onClick={() => {
                      setNextStep(0);
                      setPreviousStep(true);
                    }}
                  />
                  <div className="commonheading">{t("VEHICLE_INFO")}</div>
                </div>
              </div>
              {/* <div className={styles.signupPage}> */}
              {/* <p className={styles.signUpPara}>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </p> */}
              <p className={styles.signUpPara}></p>
              <Form onSubmit={handleSubmit(onSubmit)} className="formSection">
                <Form.Group className="mb-3">
                  <Form.Label>{t("LICENSE_PLATE_NUMBER")}</Form.Label>
                  <Form.Control
                    placeholder={`${t("ENTER_LICENSE")}`}
                    type="text"
                    {...register("LicencePlateNumber", {
                      onChange: (e) => handleKeyPress(e),
                      required: {
                        value: true,
                        message: `${t("LICENSE_REQUIRED")}`,
                      },
                      minLength: {
                        value: 2,
                        message: `${t("LICENSE_MINLENGTH")}`,
                      },
                      maxLength: {
                        value: 50,
                        message: `${t("LICENSE_MAXLENGTH")}`,
                      },
                      pattern: {
                        value: /^(?=.*[a-zA-Z])(?=.*\d).+$/,
                        message: `${t("LICENSE_PATTERN_MATCH")}`,
                      },
                      ...sanitizeHtmlTags(),
                    })}
                  />
                  {errors.LicencePlateNumber && (
                    <span className="errorMsg">
                      {errors.LicencePlateNumber.message}
                    </span>
                  )}
                </Form.Group>
                <Form.Group className="mb-3 setIconPassword">
                  <Form.Label>{t("VAN_TYPE")}</Form.Label>
                  <Form.Control
                    placeholder={`${t("SELECT_VAN")}`}
                    type="text"
                    readOnly
                    onClick={() => setVehicleTypeModal(true)}
                    {...register("vanType", {
                      onChange: (e) => handleKeyPress(e),
                      required: {
                        value: true,
                        message: `${t("VAN_REQUIRED")}`,
                      },
                    })}
                    style={{ cursor: "pointer" }}
                  />
                  {errors.vanType && (
                    <span className="errorMsg">{errors.vanType.message}</span>
                  )}
                  <div className={styles.downiconicon}>
                    <TiArrowSortedDown
                      onClick={() => setVehicleTypeModal(true)}
                    />
                  </div>
                </Form.Group>
                <Form.Group className="mb-3 setIconPassword">
                  <Form.Label>{t("COLOR_VEHICLE")}</Form.Label>
                  <Form.Control
                    placeholder={`${t("SELECT_COLOR")}`}
                    type="text"
                    readOnly
                    onClick={() => setVehicleColorModal(true)}
                    {...register("vehicleColor", {
                      onChange: (e) => handleKeyPress(e),
                      required: {
                        value: true,
                        message: `${t("COLOR_REQUIRED")}`,
                      },
                    })}
                    style={{ cursor: "pointer" }}
                  />
                  {errors.vehicleColor && (
                    <span className="errorMsg">
                      {errors.vehicleColor.message}
                    </span>
                  )}
                  <div className={styles.downiconicon}>
                    <TiArrowSortedDown
                      onClick={() => setVehicleColorModal(true)}
                    />
                  </div>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>{t("VEHICLE_YEAR")}</Form.Label>
                  <Controller
                    control={control}
                    name="vehicleYear"
                    render={({ field }) => (
                      <div>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DesktopDatePicker
                            defaultValue={dayjs(
                              `${signupFormData && signupFormData.selectedYear}`
                            )}
                            className="muiYearSelection"
                            views={["year"]}
                            maxDate={dayjs(maxDate)}
                            onChange={handleDateChange}
                            renderInput={(params) => <input {...params} />}
                          />
                        </LocalizationProvider>
                      </div>
                    )}
                    rules={{ validate: validateYear }}
                  />
                  {!selectedYear && errors.vehicleYear && (
                    <span className="errorMsg">
                      {errors.vehicleYear.message}
                    </span>
                  )}
                </Form.Group>
                <Form.Group className="mb-3">
                  <div className={styles.uploadSection}>
                    <h4>{t("VEHICLE_PHOTO")}</h4>
                    <div className={styles.uploadImage}>
                      <Controller
                        control={control}
                        name="vehicleImage"
                        render={({ field }) => (
                          <input
                            id="uploadImage"
                            type="file"
                            style={{ display: "none" }}
                            accept="image/*"
                            onChange={(e) => {
                              onImageChange(e);
                              field.onChange(e);
                            }}
                          />
                        )}
                        rules={{ validate: validateImage }}
                      />
                      <div className={styles.cancelIcon}>
                        {vehicleImage && (
                          <MdCancel
                            onClick={(e) => {
                              onImageRemove(e);
                            }}
                          />
                        )}
                      </div>
                      {vehicleImage ? (
                        <div className={styles.uploadInputImg}>
                          <img src={vehicleImage} />
                        </div>
                      ) : (
                        <div className={styles.uploadInput}>
                          <img src={uploadImg} alt="upload-image" />
                          <p>{t("UPLOAD_VEHICLE")}</p>
                        </div>
                      )}
                      <div className={styles.uploadIcon}>
                        <label
                          htmlFor="uploadImage"
                          className={styles.uploadbutton}
                        >
                          <CiCamera /> {t("UPLOAD")}
                        </label>
                      </div>
                    </div>
                    <div className="otpError">
                      {!vehicleImage && errors.vehicleImage && (
                        <span className="errorMsg">
                          {errors.vehicleImage.message}
                        </span>
                      )}
                    </div>
                  </div>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>{t("DESCRIPTION")}</Form.Label>
                  <Form.Control
                    placeholder={`${t("ENTER_DISCRIPTION")}`}
                    as="textarea"
                    rows={3}
                    {...register("vehicleDescription", {
                      onChange: (e) => handleKeyPress(e),
                      required: {
                        value: true,
                        message: `${t("DESCIPTION_REQUIRED")}`,
                      },
                      minLength: {
                        value: 2,
                        message: `${t("DESCRIPTION_MINLENGTH")}`,
                      },
                      maxLength: {
                        value: 300,
                        message: `${t("DESCRIPTION_MAXLENGTH")}`,
                      },
                    })}
                  />
                  {errors.vehicleDescription && (
                    <span className="errorMsg">
                      {errors.vehicleDescription.message}
                    </span>
                  )}
                </Form.Group>

                <div className="commonButton">
                  <button type="submit">{t("NEXT")}</button>
                </div>
              </Form>
              {/* </div> */}
            </div>
          </Container>
        </div>
      </div>
      <VehicleTypeModal
        setVehicleTypeModal={setVehicleTypeModal}
        vehicleTypeModal={vehicleTypeModal}
        selectVehicle={selectVehicle}
        setSelectVehicle={setSelectVehicle}
        setValue={setValue}
        clearErrors={clearErrors}
      />
      <VehicletColorModal
        setVehicleColorModal={setVehicleColorModal}
        vehicleColorModal={vehicleColorModal}
        setValue={setValue}
        selectVehicleColor={selectVehicleColor}
        setSelectVehicleColor={setSelectVehicleColor}
        clearErrors={clearErrors}
      />
    </div>
  );
}
export default SignUpSecondForm;
