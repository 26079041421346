import { Container, Form } from "react-bootstrap";
import backgroundImage from "../../../assets/DriverImages/Bg.webp";
import "../../../assets/Styles/DriverCommon.css";
import styles from "../DriverSignUp/DriverSignUp.module.css";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { FiArrowLeft } from "react-icons/fi";
import { useState } from "react";
import { FiCamera } from "react-icons/fi";
import uploadImg from "../../../assets/DriverImages/uploadIcon.png";
import { MdCancel } from "react-icons/md";
import DocumentAlert from "./DocumentAlert";
import { useDispatch, useSelector } from "react-redux";
import {
  clearForm,
  driverSignup,
  resetNextStep,
  setSocialUserData,
} from "../../../store/slices/UserSlice";
import { SIGNUP_TYPE } from "../../../utils/Constants";
import { STATUS_MSG } from "../../../utils/StatusMsg";
import { STATUS_CODES } from "../../../utils/StatusCode";
import Toster from "../../../utils/Toaster";
import { useNavigate } from "react-router-dom";
import Loader from "../../../utils/Loader/Loader";
import mime from "mime";
import pdf from "../../../assets/DriverImages/pdf.png";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

//---------function for signUp final step form----------
function SignUpFinal({ setNextStep, setPreviousStep }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    handleSubmit,
    control,
    clearErrors,
    formState: { errors },
  } = useForm();

  const [licencePreview, setLicencePreview] = useState("");
  const [licenceImage, setLicenceImage] = useState("");
  const [gtInsurancePreview, setGtInsurancePreview] = useState("");
  const [gtInsuranceImage, setGtInsuranceImage] = useState("");
  const [hrInsurancePreview, setHrInsurancePreview] = useState("");
  const [hrInsuranceImage, setGtHrsuranceImage] = useState("");
  const [plInsurancePreview, setPlInsurancePreview] = useState("");
  const [plInsuranceImage, setPlsuranceImage] = useState("");
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [title, setTitle] = useState("");
  const [id, setId] = useState("");
  const { signupFormData, isLoading, socialUserData } = useSelector(
    (state) => state.user
  );
  const [dlDate, setDlDate] = useState("");
  const [formatedDl, setFormatedDl] = useState("");
  const [gtDate, setGtDate] = useState("");
  const [formatedGt, setFormatedGt] = useState("");
  const [hrDate, setHrDate] = useState("");
  const [formatedHr, setFormatedHr] = useState("");
  const [plDate, setPlDate] = useState("");
  const [formatedPl, setFormatedPl] = useState("");
  const currentDate = new Date();

  // function handle date change
  const handleDateChange1 = (date) => {
    setDlDate(date);
    if (date) {
      const formattedYear = dayjs(date).format("YYYY-MM-DD");
      setFormatedDl(formattedYear);
    } else {
      setFormatedDl("");
    }
  };
  const handleDateChange2 = (date) => {
    setGtDate(date);
    if (date) {
      const formattedYear = dayjs(date).format("YYYY-MM-DD");
      setFormatedGt(formattedYear);
    } else {
      setFormatedGt("");
    }
  };
  const handleDateChange3 = (date) => {
    setHrDate(date);
    if (date) {
      const formattedYear = dayjs(date).format("YYYY-MM-DD");
      setFormatedHr(formattedYear);
    } else {
      setFormatedHr("");
    }
  };
  const handleDateChange4 = (date) => {
    setPlDate(date);
    if (date) {
      const formattedYear = dayjs(date).format("YYYY-MM-DD");
      setFormatedPl(formattedYear);
    } else {
      setFormatedPl("");
    }
  };

  // function for apply validation in date field
  const validateDlDate = () => {
    if (!dlDate) {
      return t("EXP_DATE_REQUIRED");
    }
    return true;
  };
  const validateGtDate = () => {
    if (!gtDate) {
      return t("EXP_DATE_REQUIRED");
    }
    return true;
  };
  const validateHrDate = () => {
    if (!hrDate) {
      return t("EXP_DATE_REQUIRED");
    }
    return true;
  };
  const validatePlDate = () => {
    if (!plDate) {
      return t("EXP_DATE_REQUIRED");
    }
    return true;
  };

  // function for apply validation in image field
  const validateImage1 = () => {
    if (!licencePreview) {
      return `${t("DRIVING_LICENSE_REQUIRED")}`;
    }
    return true;
  };
  const validateImage2 = () => {
    if (!gtInsurancePreview) {
      return `${t("GT_INSURANCE_REQUIRED")}`;
    }
    return true;
  };
  const validateImage3 = () => {
    if (!hrInsurancePreview) {
      return `${t("HR_INSURANCE_REQUIRED")}`;
    }
    return true;
  };
  const validateImage4 = () => {
    if (!plInsurancePreview) {
      return `${t("PL_INSURANCE_REQUIRED")}`;
    }
    return true;
  };

  //----- function for remove uploaded images-----
  function onImageRemove(id) {
    switch (id) {
      case "file1":
        setLicencePreview("");
        setLicenceImage("");
        break;
      case "file2":
        setGtInsurancePreview("");
        setGtInsuranceImage("");
        break;
      case "file3":
        setHrInsurancePreview("");
        setGtHrsuranceImage("");
        break;
      case "file4":
        setPlInsurancePreview("");
        setPlsuranceImage("");
        break;
      default:
        break;
    }
  }

  //----- function for Driving Licence-----
  function onImageChange1(e) {
    if (e.target.files.length !== 0) {
      const file = e.target.files[0];
      const fileSizeInMB = (file.size / (1024 * 1024)).toFixed(2);
      if (fileSizeInMB > 5) {
        Toster(t("FILE_SIZE_LARGE"), "error");
        return;
      }
      setLicencePreview(URL.createObjectURL(e.target.files[0]));
      setLicenceImage(e.target.files[0]);
    }
  }

  //----- function for Goods In Transit Insurance -----
  function onImageChange2(e) {
    if (e.target.files.length !== 0) {
      const file = e.target.files[0];
      const fileSizeInMB = (file.size / (1024 * 1024)).toFixed(2);
      if (fileSizeInMB > 5) {
        Toster(t("FILE_SIZE_LARGE"), "error");
        return;
      }
      setGtInsurancePreview(URL.createObjectURL(e.target.files[0]));
      setGtInsuranceImage(e.target.files[0]);
    }
  }

  //----- function for Hire & Reward Insurance -----
  function onImageChange3(e) {
    if (e.target.files.length !== 0) {
      const file = e.target.files[0];
      const fileSizeInMB = (file.size / (1024 * 1024)).toFixed(2);
      if (fileSizeInMB > 5) {
        Toster(t("FILE_SIZE_LARGE"), "error");
        return;
      }
      setHrInsurancePreview(URL.createObjectURL(e.target.files[0]));
      setGtHrsuranceImage(e.target.files[0]);
    }
  }

  //----- function for Public Liability Insurance -----
  function onImageChange4(e) {
    if (e.target.files.length !== 0) {
      const file = e.target.files[0];
      const fileSizeInMB = (file.size / (1024 * 1024)).toFixed(2);
      if (fileSizeInMB > 5) {
        Toster(t("FILE_SIZE_LARGE"), "error");
        return;
      }
      setPlInsurancePreview(URL.createObjectURL(e.target.files[0]));
      setPlsuranceImage(e.target.files[0]);
    }
  }

  function b64toBlob(cropData, contentType, sliceSize) {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;
    var byteCharacters = window.atob(cropData); //decode string
    var byteArrays = [];
    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);
      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  function getRandom() {
    return Math.floor(Math.random() * 9000000000) + 1000000000;
  }
  // --------function for driver signup api calling--------
  const onSubmit = async () => {
    let vehicleImg = "";

    var blockCrop =
      signupFormData && signupFormData.vehicleImage
        ? signupFormData.vehicleImage.split(";")
        : [];
    if (blockCrop.length < 2) {
      console.error("Invalid selected file format");
      return; // Or handle the error accordingly
    }
    // Get the content type of the image
    var cropContentTypes = blockCrop[0].split(":")[1]; // In this case "image/png"
    // get the real base64 content of the file
    var blockRealData = blockCrop[1].split(",")[1];
    var cropBlobImg = await b64toBlob(blockRealData, cropContentTypes);
    vehicleImg = new FormData();
    vehicleImg.append(
      "vehicle_image",
      cropBlobImg,
      getRandom() + "." + mime.getExtension(cropBlobImg.type)
    );

    let requestData = new FormData();
    requestData.append(
      "signup_type",
      socialUserData && socialUserData.id
        ? SIGNUP_TYPE.SOCIAL
        : SIGNUP_TYPE.NORMAL
    );
    requestData.append(
      "first_name",
      signupFormData.firstName ? signupFormData.firstName.trim() : ""
    );
    requestData.append(
      "last_name",
      signupFormData.lastName ? signupFormData.lastName.trim() : ""
    );
    requestData.append(
      "email",
      signupFormData.email ? signupFormData.email.trim() : ""
    );
    {
      signupFormData.driverCompany &&
        requestData.append(
          "company_name",
          signupFormData.driverCompany
            ? signupFormData.driverCompany.trim()
            : ""
        );
    }
    requestData.append(
      "dial_code",
      signupFormData.dialCode ? `+${signupFormData.dialCode}` : ""
    );
    requestData.append(
      "phone_country_code",
      signupFormData.countryCode ? signupFormData.countryCode : ""
    );
    requestData.append(
      "phone_num",
      signupFormData.phoneNo ? signupFormData.phoneNo : ""
    );
    requestData.append(
      "location",
      signupFormData.businessLocation
        ? signupFormData.businessLocation.address
        : ""
    );
    requestData.append(
      "latitude",
      signupFormData.businessLocation
        ? signupFormData.businessLocation.lat?.toFixed(6)
        : ""
    );
    requestData.append(
      "longitude",
      signupFormData.businessLocation
        ? signupFormData.businessLocation.lng?.toFixed(6)
        : ""
    );
    if (socialUserData && socialUserData.id) {
      requestData.append("social_id", socialUserData && socialUserData.id);
      requestData.append(
        "social_type",
        socialUserData && socialUserData.socialType
      );
    } else {
      requestData.append(
        "password",
        signupFormData.password ? signupFormData.password.trim() : ""
      );
      requestData.append(
        "cnf_password",
        signupFormData.confirmPassword
          ? signupFormData.confirmPassword.trim()
          : ""
      );
    }
    requestData.append(
      "licence_plate_number",
      signupFormData.LicencePlateNumber ? signupFormData.LicencePlateNumber : ""
    );
    requestData.append(
      "vehicle_type_id",
      signupFormData.selectVehicle && signupFormData.selectVehicle.id
        ? signupFormData.selectVehicle.id
        : ""
    );
    requestData.append(
      "vehicle_colour_id",
      signupFormData.selectVehicleColor && signupFormData.selectVehicleColor.id
        ? signupFormData.selectVehicleColor.id
        : ""
    );
    requestData.append(
      "vehicle_description",
      signupFormData.vehicleDescription ? signupFormData.vehicleDescription : ""
    );
    requestData.append(
      "vehicle_year",
      signupFormData.selectedYear ? signupFormData.selectedYear : ""
    );
    requestData.append("vehicle_image", vehicleImg.get("vehicle_image"));
    requestData.append("driving_lincence", licenceImage ? licenceImage : "");
    requestData.append(
      "gt_insurance",
      gtInsuranceImage ? gtInsuranceImage : ""
    );
    requestData.append(
      "hr_insurance",
      hrInsuranceImage ? hrInsuranceImage : ""
    );
    requestData.append(
      "pl_insurance",
      plInsuranceImage ? plInsuranceImage : ""
    );
    requestData.append("dl_expire_date", formatedDl ? formatedDl : "");
    requestData.append("gt_expire_date", formatedGt ? formatedGt : "");
    requestData.append("hr_expire_date", formatedHr ? formatedHr : "");
    requestData.append("pl_expire_date", formatedPl ? formatedPl : "");

    requestData.append(
      "bank_name",
      signupFormData.bankName ? signupFormData.bankName : ""
    );
    requestData.append(
      "bank_account_no",
      signupFormData.bankAccountNumber ? signupFormData.bankAccountNumber : ""
    );
    requestData.append(
      "sort_code",
      signupFormData.bankSortCode ? signupFormData.bankSortCode : ""
    );

    dispatch(driverSignup(requestData)).then((responsejson) => {
      const response = responsejson.payload;

      const msg = response && response.data && response.data.message;

      const ResponseCode =
        STATUS_MSG[response && response.data && response.data.code];
      if (response && response.code === STATUS_CODES.SUCCESS) {
        // Toster(t("CREATE_ACCOUNT_SUCCESSFUL"), "success");
        dispatch(clearForm());
        dispatch(resetNextStep());
        dispatch(setPreviousStep(false));
        dispatch(setSocialUserData({}));
        navigate("/driver-home");
      } else {
        if (
          response &&
          response.data &&
          response.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  return (
    <div className="main">
      {isLoading === true ? <Loader /> : ""}
      <div className="spaceTopManageDriver">
        <div
          style={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: "cover",
            width: "100%",
            padding: "20px 0px",
            backgroundPositionX: "center",
          }}
        >
          <Container>
            <div className={styles.formBlocks}>
              <div className={styles.signPageCls}>
                <span>
                  <strong>4</strong>/4
                </span>
                <div className={styles.signPageArrow}>
                  <FiArrowLeft
                    onClick={() => {
                      setNextStep(2);
                      setPreviousStep(true);
                    }}
                  />
                  <div className="commonheading">{t("UPLOAD_DOCUMENT")}</div>
                </div>
              </div>
              {/* <div className={styles.signupPage}> */}
              {/* <p className={styles.signUpPara}>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </p> */}
              <Form onSubmit={handleSubmit(onSubmit)} className="formSection">
                {/* Driving Licence */}

                <Form.Group className="mb-3">
                  <div
                    className={`${styles.uploadSection} ${styles.uploadSectionSpace}`}
                  >
                    <h4>{t("DRIVING_LICENSE")}</h4>
                    <div className={styles.uploadImage}>
                      <Controller
                        control={control}
                        name="drivingLicense"
                        render={({ field }) => (
                          <input
                            id="file1"
                            type="file"
                            style={{ display: "none" }}
                            accept="image/*,.pdf"
                            onChange={(e) => {
                              field.onChange(e);
                              clearErrors("drivingLicense");
                              onImageChange1(e);
                            }}
                          />
                        )}
                        rules={{ validate: validateImage1 }}
                      />
                      <div className={styles.cancelIcon}>
                        {licencePreview && (
                          <MdCancel
                            onClick={() => {
                              setShowAlertModal(true);
                              setTitle(`${t("DRIVING_LICENSE")}`);
                              setId("file1");
                            }}
                          />
                        )}
                      </div>

                      {licencePreview ? (
                        <div className={styles.uploadInputImg}>
                          {licenceImage.type.startsWith("image") ? (
                            <img src={licencePreview} alt="uploaded" />
                          ) : (
                            <img src={pdf} alt="pdf-icon" />
                          )}
                        </div>
                      ) : (
                        <div className={styles.uploadInput}>
                          <img src={uploadImg} alt="upload-image" />
                          <p>{t("UPLOAD_LICENSE")}</p>
                        </div>
                      )}
                      <div className={styles.uploadIcon}>
                        <label htmlFor="file1" className={styles.uploadbutton}>
                          <FiCamera /> {t("UPLOAD")}
                        </label>
                      </div>
                    </div>
                    <div className="otpError">
                      {!licencePreview && errors.drivingLicense && (
                        <span className="errorMsg">
                          {errors.drivingLicense.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <h4 className={styles.dateHeading}>
                    {t("DRIVING_LICENSE")} {t("EXPIRE_DATE")}
                  </h4>
                  <Form.Group className="mb-3 dateInput">
                    <Controller
                      control={control}
                      name="dlDate"
                      render={({ field }) => (
                        <div>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                              value={dlDate}
                              className="muiDatepickerfull muiFullYearSelection"
                              views={["year", "month", "day"]}
                              minDate={dayjs(currentDate)}
                              onChange={handleDateChange1}
                              renderInput={(params) => <input {...params} />}
                            />
                          </LocalizationProvider>
                        </div>
                      )}
                      rules={{ validate: validateDlDate }}
                    />
                    <div className="otpError">
                      {!dlDate && errors.dlDate && (
                        <span className="errorMsg">
                          {errors.dlDate.message}
                        </span>
                      )}
                    </div>
                  </Form.Group>
                </Form.Group>

                {/* Goods In Transit Insurance  */}
                <Form.Group className="mb-3">
                  <div className={styles.uploadSection}>
                    <h4>{t("GT_TITLE")}</h4>
                    <div className={styles.uploadImage}>
                      <Controller
                        control={control}
                        name="gtInsurance"
                        render={({ field }) => (
                          <input
                            id="file2"
                            type="file"
                            style={{ display: "none" }}
                            accept="image/*,.pdf"
                            onChange={(e) => {
                              field.onChange(e);
                              clearErrors("gtInsurance");
                              onImageChange2(e);
                            }}
                          />
                        )}
                        rules={{ validate: validateImage2 }}
                      />
                      <div className={styles.cancelIcon}>
                        {gtInsurancePreview && (
                          <MdCancel
                            onClick={() => {
                              setShowAlertModal(true);
                              setTitle(`${t("GT_TITLE")}`);
                              setId("file2");
                            }}
                          />
                        )}
                      </div>
                      {gtInsurancePreview ? (
                        <div className={styles.uploadInputImg}>
                          {gtInsuranceImage.type.startsWith("image") ? (
                            <img src={gtInsurancePreview} alt="uploaded" />
                          ) : (
                            <img src={pdf} alt="pdf-icon" />
                          )}
                        </div>
                      ) : (
                        <div className={styles.uploadInput}>
                          <img src={uploadImg} alt="upload-image" />
                          <p>{t("UPLOAD_TRAN_INSURANCE")}</p>
                        </div>
                      )}
                      <div className={styles.uploadIcon}>
                        <label htmlFor="file2" className={styles.uploadbutton}>
                          <FiCamera /> {t("UPLOAD")}
                        </label>
                      </div>
                    </div>
                    <div className="otpError">
                      {!gtInsurancePreview && errors.gtInsurance && (
                        <span className="errorMsg">
                          {errors.gtInsurance.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <h4 className={styles.dateHeading}>
                    {t("GT_TITLE")} {t("EXPIRE_DATE")}
                  </h4>
                  <Form.Group className="mb-3 dateInput">
                    <Controller
                      control={control}
                      name="gtDate"
                      render={({ field }) => (
                        <div>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                              value={gtDate}
                              className="muiDatepickerfull muiFullYearSelection"
                              views={["year", "month", "day"]}
                              minDate={dayjs(currentDate)}
                              onChange={handleDateChange2}
                              renderInput={(params) => <input {...params} />}
                            />
                          </LocalizationProvider>
                        </div>
                      )}
                      rules={{ validate: validateGtDate }}
                    />
                    <div className="otpError">
                      {!gtDate && errors.gtDate && (
                        <span className="errorMsg">
                          {errors.gtDate.message}
                        </span>
                      )}
                    </div>
                  </Form.Group>
                </Form.Group>

                {/*  Hire & Reward Insurance */}
                <Form.Group className="mb-3">
                  <div className={styles.uploadSection}>
                    <h4>{t("HR_TITLE")}</h4>
                    <div className={styles.uploadImage}>
                      <Controller
                        control={control}
                        name="hrInsurance"
                        render={({ field }) => (
                          <input
                            id="file3"
                            type="file"
                            style={{ display: "none" }}
                            accept="image/*,.pdf"
                            onChange={(e) => {
                              field.onChange(e);
                              clearErrors("hrInsurance");
                              onImageChange3(e);
                            }}
                          />
                        )}
                        rules={{ validate: validateImage3 }}
                      />
                      <div className={styles.cancelIcon}>
                        {hrInsurancePreview && (
                          <MdCancel
                            onClick={() => {
                              setShowAlertModal(true);
                              setTitle(`${t("HR_TITLE")}`);
                              setId("file3");
                            }}
                          />
                        )}
                      </div>
                      {hrInsurancePreview ? (
                        <div className={styles.uploadInputImg}>
                          {hrInsuranceImage.type.startsWith("image") ? (
                            <img src={hrInsurancePreview} alt="uploaded" />
                          ) : (
                            <img src={pdf} alt="pdf-icon" />
                          )}
                        </div>
                      ) : (
                        <div className={styles.uploadInput}>
                          <img src={uploadImg} alt="upload-image" />
                          <p>{t("UPLOAD_REWARD_INSURANCE")}</p>
                        </div>
                      )}
                      <div className={styles.uploadIcon}>
                        <label htmlFor="file3" className={styles.uploadbutton}>
                          <FiCamera /> {t("UPLOAD")}
                        </label>
                      </div>
                    </div>
                    <div className="otpError">
                      {!hrInsurancePreview && errors.hrInsurance && (
                        <span className="errorMsg">
                          {errors.hrInsurance.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <h4 className={styles.dateHeading}>
                    {t("HR_TITLE")} {t("EXPIRE_DATE")}
                  </h4>
                  <Form.Group className="mb-3 dateInput">
                    <Controller
                      control={control}
                      name="hrDate"
                      render={({ field }) => (
                        <div>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                              value={hrDate}
                              className="muiDatepickerfull muiFullYearSelection"
                              views={["year", "month", "day"]}
                              minDate={dayjs(currentDate)}
                              onChange={handleDateChange3}
                              renderInput={(params) => <input {...params} />}
                            />
                          </LocalizationProvider>
                        </div>
                      )}
                      rules={{ validate: validateHrDate }}
                    />
                    <div className="otpError">
                      {!hrDate && errors.hrDate && (
                        <span className="errorMsg">
                          {errors.hrDate.message}
                        </span>
                      )}
                    </div>
                  </Form.Group>
                </Form.Group>

                {/*  Public Liability Insurance */}
                <Form.Group className="mb-3">
                  <div className={styles.uploadSection}>
                    <h4>{t("PL_TITLE")}</h4>
                    <div className={styles.uploadImage}>
                      <Controller
                        control={control}
                        name="plInsurance"
                        render={({ field }) => (
                          <input
                            id="file4"
                            type="file"
                            style={{ display: "none" }}
                            accept="image/*,.pdf"
                            onChange={(e) => {
                              field.onChange(e);
                              clearErrors("plInsurance");
                              onImageChange4(e);
                            }}
                          />
                        )}
                        rules={{ validate: validateImage4 }}
                      />
                      <div className={styles.cancelIcon}>
                        {plInsurancePreview && (
                          <MdCancel
                            onClick={() => {
                              setShowAlertModal(true);
                              setTitle(`${t("PL_TITLE")}`);
                              setId("file4");
                            }}
                          />
                        )}
                      </div>
                      {plInsurancePreview ? (
                        <div className={styles.uploadInputImg}>
                          {plInsuranceImage.type.startsWith("image") ? (
                            <img src={plInsurancePreview} alt="uploaded" />
                          ) : (
                            <img src={pdf} alt="pdf-icon" />
                          )}
                        </div>
                      ) : (
                        <div className={styles.uploadInput}>
                          <img src={uploadImg} alt="upload-image" />
                          <p>{t("UPLOAD_LIABILITY_INSURANCE")}</p>
                        </div>
                      )}
                      <div className={styles.uploadIcon}>
                        <label htmlFor="file4" className={styles.uploadbutton}>
                          <FiCamera /> {t("UPLOAD")}
                        </label>
                      </div>
                    </div>
                    <div className="otpError">
                      {!plInsurancePreview && errors.plInsurance && (
                        <span className="errorMsg">
                          {errors.plInsurance.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <h4 className={styles.dateHeading}>
                    {t("PL_TITLE")} {t("EXPIRE_DATE")}
                  </h4>
                  <Form.Group className="mb-3 dateInput">
                    <Controller
                      control={control}
                      name="plDate"
                      render={({ field }) => (
                        <div>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                              value={plDate}
                              className="muiDatepickerfull muiFullYearSelection"
                              views={["year", "month", "day"]}
                              minDate={dayjs(currentDate)}
                              onChange={handleDateChange4}
                              renderInput={(params) => <input {...params} />}
                            />
                          </LocalizationProvider>
                        </div>
                      )}
                      rules={{ validate: validatePlDate }}
                    />
                    <div className="otpError">
                      {!plDate && errors.plDate && (
                        <span className="errorMsg">
                          {errors.plDate.message}
                        </span>
                      )}
                    </div>
                  </Form.Group>
                </Form.Group>

                <div className="commonButton">
                  <button type="submit">{t("SIGNUP_TEXT")}</button>
                </div>
              </Form>
              {/* </div> */}
            </div>
          </Container>
        </div>
      </div>
      <DocumentAlert
        setShowAlertModal={setShowAlertModal}
        showAlertModal={showAlertModal}
        onImageRemove={onImageRemove}
        title={title}
        id={id}
        isDelete={false}
      />
    </div>
  );
}
export default SignUpFinal;
