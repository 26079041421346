import "../../assets/Styles/Common.css";
import styles from "../ForgotPassword/ForgotPassword.module.css";
import { useTranslation } from "react-i18next";
import backgroundImage from "../../assets/Images/forgotBg.webp";
import lock from "../../assets/Images/lock.png";
import { Container, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { sanitizeHtmlTags } from "../../utils/sanitizeHtmlTags";
import { useNavigate } from "react-router-dom";
import SublyApi from "../../helpers/Api";
import { STATUS_CODES } from "../../utils/StatusCode";
import { STATUS_MSG } from "../../utils/StatusMsg";
import Toster from "../../utils/Toaster";
import { useState } from "react";
import Loader from "../../utils/Loader/Loader";

//---------function for forgot password----------
function ForgotPassword() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const [loader, setLoader] = useState(false);

  // disable space on key press input email field
  const onInputChange = (e) => {
    setValue(e.target.name, e.target.value.replace(/\s/g, "")); // this remove from all string
  };

  //-----------function for forgot password api call-----------
  const onSubmit = async (formdata) => {
    let requestData = new FormData();
    requestData.append(
      "email",
      formdata ? formdata.email && formdata.email.trim() : ""
    );
    setLoader(true);
    await SublyApi.forgotPassword(requestData).then((response) => {
      const msg = response && response.data && response.data.message;
      const ResponseCode =
        STATUS_MSG[response && response.data && response.data.code];
      if (response && response.code === STATUS_CODES.SUCCESS) {
        setLoader(false);
        Toster(t("RESET_SUCCESFULLY"), "success");
        navigate("/reset-password", {
          state: {
            email: formdata.email,
          },
        });
      } else {
        setLoader(false);
        if (
          response &&
          response.data &&
          response.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  return (
    <div className="main">
      {loader && <Loader />}
      <div className="spaceTopManage">
        <div
          style={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: "cover",
            width: "100%",
            padding: "20px 0px",
            backgroundPositionX: "center",
          }}
        >
          <Container>
            <div className={styles.formBlock}>
              <div className={styles.logoImage}>
                <img src={lock} alt="logo-image" />
              </div>
              <div className={styles.forgotText}>
                <h3>{t("FORGOT_PASSWORD")}</h3>
                <p>{t("FORGOT_TEXT")}</p>
              </div>
              <Form onSubmit={handleSubmit(onSubmit)} className="formSection">
                <Form.Group className="mb-3">
                  <Form.Label>{t("EMAIL")}</Form.Label>
                  <Form.Control
                    placeholder={`${t("EMAIL_TEXT")}`}
                    type="text"
                    {...register("email", {
                      onChange: (e) => onInputChange(e),
                      required: {
                        value: true,
                        message: `${t("EMAIL_REQUIRED")}`,
                      },
                      pattern: {
                        value:
                          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: `${t("EMAIL_INVALID")}`,
                      },
                      ...sanitizeHtmlTags(),
                    })}
                  />
                  {errors.email && (
                    <span className="errorMsg">{errors.email.message}</span>
                  )}
                </Form.Group>

                <div className={styles.buttonControls}>
                  <button
                    type="button"
                    onClick={() => navigate("/")}
                    className={styles.leftButton}
                  >
                    {t("CANCEL")}
                  </button>
                  <button type="submit" className={styles.rightButton}>
                    {t("RESET")}
                  </button>
                </div>
              </Form>
            </div>
          </Container>
        </div>
      </div>
    </div>
  );
}
export default ForgotPassword;
