import { Col, Container, Row } from "react-bootstrap";
import { FiArrowLeft } from "react-icons/fi";
import styles from "../Schedule/Schedule.module.css";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { lazy, useEffect, useRef, useState } from "react";
import SublyApi from "../../../helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import { STATUS_MSG } from "../../../utils/StatusMsg";
import { STATUS_CODES } from "../../../utils/StatusCode";
import { userLogoutClear } from "../../../store/slices/UserSlice";
import Toster from "../../../utils/Toaster";
import Loader from "../../../utils/Loader/Loader";
// import ReactPlayer from "react-player";
import "../../../assets/Styles/DriverCommon.css";
import { IoPlayCircle } from "react-icons/io5";
const Player = lazy(() => import("react-player/lazy"));

function TutoriaVideo() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { TutorialId } = useParams();
  const { userToken } = useSelector((state) => state.user);

  const [loader, setLoader] = useState(false);
  const [tutorialList, setTutorialList] = useState([]);

  async function TutoriaVideoList() {
    setLoader(true);
    try {
      const response = await SublyApi.getDriverTutorial(userToken, TutorialId);
      const msg = response?.data?.message;
      const ResponseCode = STATUS_MSG[response?.data?.code];
      if (response?.code === STATUS_CODES.SUCCESS) {
        setTutorialList(response?.data?.tutorialList || []);
      } else if (response?.data?.code === STATUS_CODES.INVALID_TOKEN) {
        dispatch(userLogoutClear());
        navigate("/driver-login");
      } else {
        Toster(msg || t(ResponseCode), "error");
      }
    } catch (error) {
      Toster(t("An error occurred"), "error");
    } finally {
      setLoader(false);
    }
  }

  useEffect(() => {
    TutoriaVideoList();
  }, []);

  const matchedTutorial = tutorialList.find(
    (tutorial) => tutorial.category_id === TutorialId
  );
  console.log(matchedTutorial, "matchedTutorial");

  const [playing, setPlaying] = useState({});
  const playerRefs = useRef({});

  const handlePlayButtonClick = (index) => {
    setPlaying((prevState) => ({ ...prevState, [index]: true }));
    playerRefs.current[index].seekTo(0);
  };

  return (
    <>
      {loader && <Loader />}
      <div className="main">
        <div className="spaceTopManageDriver">
          <div className={styles.bgColor}>
            <Container>
              <h6>{t("TUTORIAL")}</h6>
              <FiArrowLeft
                onClick={() => {
                  navigate(-1);
                }}
              />
            </Container>
          </div>
          <Container>
            <h1 className={styles.textHeading}>
              {location?.state?.categoryName}
            </h1>
            <div className="formTopSpaceCls">
              <div className={styles.tutorialBox}>
                <Row>
                  {matchedTutorial?.videos?.length > 0 ? (
                    matchedTutorial.videos.map((video, videoIndex) => (
                      <Col sm={6} md={6} xl={4} lg={4} key={videoIndex}>
                        <div key={videoIndex} className="player-wrapper">
                          <Player
                            ref={(ref) =>
                              (playerRefs.current[videoIndex] = ref)
                            }
                            url={video.name}
                            controls
                            width="100%"
                            height="100%"
                            style={{ borderRadius: "9px" }}
                            playing={playing[videoIndex] || false}
                            className="react-player"
                          />
                          {!playing[videoIndex] && (
                            <div
                              className="play-button-overlay"
                              onClick={() => handlePlayButtonClick(videoIndex)}
                            >
                              <button className="play-button">
                                <IoPlayCircle />
                              </button>
                            </div>
                          )}
                        </div>
                      </Col>
                    ))
                  ) : (
                    <p style={{ textAlign: "center" }}>
                      {t("No videos available")}
                    </p>
                  )}
                  <p
                    className={styles.tutorialBottomText}
                    dangerouslySetInnerHTML={{
                      __html: matchedTutorial?.description.replace(
                        /\r\n/g,
                        "<br />"
                      ),
                    }}
                  />
                </Row>
              </div>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
}

export default TutoriaVideo;
