import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styles from "./Info.module.css";
import { Container } from "react-bootstrap";
import SublyApi from "../../helpers/Api";
import { STATUS_MSG } from "../../utils/StatusMsg";
import { STATUS_CODES } from "../../utils/StatusCode";
import { SETTING_OPTION, SETTING_OPTION_TYPE } from "../../utils/Constants";
import { useTranslation } from "react-i18next";
import Toster from "../../utils/Toaster";
import DOMPurify from "dompurify";
import Loader from "../../utils/Loader/Loader";
import { Helmet } from "react-helmet-async";

export default function Info() {
  const location = useLocation();
  const { t } = useTranslation();
  const [infoData, setInfoData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const renderHeading = () => {
    if (location.state == "privacy") {
      return t("PRIVACY");
    } else {
      return t("TERMS");
    }
  };

  const getInfoType = () => {
    if (location.state == "privacy") {
      return SETTING_OPTION.PRIVACY_POLICY;
    } else {
      return SETTING_OPTION.TermsConditions;
    }
  };

  const fetchInfoData = async () => {
    setIsLoading(true);
    await SublyApi.getCustomerSettingList(
      null,
      SETTING_OPTION_TYPE.PAGE,
      getInfoType()
    ).then((response) => {
      const msg = response && response.data && response.data.message;
      const ResponseCode =
        STATUS_MSG[response && response.data && response.data.code];
      setIsLoading(false);
      if (response && response.code === STATUS_CODES.SUCCESS) {
        const dataText = response.data[0]?.option_value || "";
        setInfoData(dataText);
      } else if (
        response &&
        response.data &&
        response.data.code === STATUS_CODES.INVALID_TOKEN
      ) {
      } else {
        if (
          response &&
          response.data &&
          response.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  useEffect(() => {
    fetchInfoData();
  }, []);

  return (
    <>
      {location.state == "privacy" ? (
        <Helmet>
          <title>
            City2City | Privacy Policy – Your Data & Security at City2City
          </title>
          <meta
            name="description"
            content="City2City - Read our privacy policy to learn how we protect your data when booking house removals, van removals, office relocations, and cost-effective removals online."
          />
          <link rel="canonical" href={window.location.href} />
        </Helmet>
      ) : (
        <Helmet>
          <title>
            City2City | Terms & Conditions – City2City Removals & Van Hire
          </title>
          <meta
            name="description"
            content="City2City - Review our terms & conditions for house removals, van removals, office relocations, man and van service, and national removals. Know your rights before booking."
          />
          <link rel="canonical" href={window.location.href} />
        </Helmet>
      )}

      <div className={styles.infoWrapper}>
        <Container>
          {isLoading && <Loader />}
          <h1>{renderHeading()}</h1>
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(infoData),
            }}
          ></div>
        </Container>
      </div>
    </>
  );
}
