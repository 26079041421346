import "../../../assets/Styles/DriverCommon.css";
import styles from "../DriverForgotPassword/DriverForgotPassword.module.css";
import { useTranslation } from "react-i18next";
import backgroundImage from "../../../assets/DriverImages/Bg.webp";
import lock from "../../../assets/DriverImages/lock.png";
import { Container, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { sanitizeHtmlTags } from "../../../utils/sanitizeHtmlTags";
import { useNavigate } from "react-router-dom";
import SublyApi from "../../../helpers/Api";
import { useState } from "react";
import { STATUS_CODES } from "../../../utils/StatusCode";
import { STATUS_MSG } from "../../../utils/StatusMsg";
import Toster from "../../../utils/Toaster";
import Loader from "../../../utils/Loader/Loader";

//---------function for forgot password----------
function DriverForgotPassword() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const [loader, setLoader] = useState(false);

  // disable space on key press input email field
  const onInputChange = (e) => {
    setValue(e.target.name, e.target.value.replace(/\s/g, "")); // this remove from all string
  };

  //-----------function for driver forgot password api call-----------
  const onSubmit = async (formdata) => {
    let requestData = new FormData();
    requestData.append(
      "email",
      formdata ? formdata.email && formdata.email.trim() : ""
    );
    setLoader(true);
    await SublyApi.driverForgotPassword(requestData).then((response) => {
      const msg = response && response.data && response.data.message;
      const ResponseCode =
        STATUS_MSG[response && response.data && response.data.code];
      if (response && response.code === STATUS_CODES.SUCCESS) {
        setLoader(false);
        Toster(t("RESET_SUCCESFULLY"), "success");
        navigate("/driver-reset-password", {
          state: {
            email: formdata.email,
          },
        });
      } else {
        setLoader(false);
        if (
          response &&
          response.data &&
          response.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  return (
    <div className="main">
      {loader && <Loader />}
      <div className="spaceTopManageDriver">
        <div
          style={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: "cover",
            width: "100%",
            height: "100%",
            padding: "20px 0px",
            backgroundPositionX: "center",
          }}
        >
          <Container>
            <div className={styles.formBlocks}>
              <div className={styles.logoImage}>
                <img src={lock} alt="logo-image" />
                <h1 className="commonheading">{t("FORGOT_PASSWORD_TEXT")}</h1>
              </div>
              <div className={styles.forgotPage}>
                <p className={styles.forgotPara}>{t("FORGOT_TEXT")}</p>
                <Form onSubmit={handleSubmit(onSubmit)} className="formSection">
                  <Form.Group className="mb-3">
                    <Form.Label>{t("EMAIL")}</Form.Label>
                    <Form.Control
                      placeholder={`${t("EMAIL_TEXT")}`}
                      type="text"
                      autoComplete="off"
                      {...register("email", {
                        onChange: (e) => onInputChange(e),
                        required: {
                          value: true,
                          message: `${t("EMAIL_REQUIRED")}`,
                        },
                        pattern: {
                          value:
                            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                          message: `${t("EMAIL_INVALID")}`,
                        },
                        ...sanitizeHtmlTags(),
                      })}
                    />
                    {errors.email && (
                      <span className="errorMsg">{errors.email.message}</span>
                    )}
                  </Form.Group>
                  {/* <div className={styles.forgotButtonSpace}> */}
                  <div className="commonButton">
                    <button type="submit">{t("SUBMIT")}</button>
                  </div>
                  <div className="cancelButton">
                    <button
                      type="button"
                      variant="light"
                      onClick={() => navigate("/driver-login")}
                      className={styles.leftButton}
                    >
                      <span
                        style={{
                          backgroundImage:
                            "linear-gradient(297.65deg, #2FC2FE 14.83%, #0094F6 83.99%)",
                          backgroundClip: "text",
                          WebkitBackgroundClip: "text",
                          WebkitTextFillColor: "transparent",
                          borderRadius: "3px",
                        }}
                      >
                        {t("CANCEL")}
                      </span>
                    </button>
                  </div>
                  {/* </div> */}
                </Form>
              </div>
            </div>
          </Container>
        </div>
      </div>
    </div>
  );
}
export default DriverForgotPassword;
